import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../providers/auth'
import { usePriorities } from '../providers/priorities'
import { ReducerActions, UserRoles } from '../util/consts'
import Button from './Button'
import Priorities from './Priorities'
import { useQuery } from 'urql'
import { GetPriorities } from '../graphql/queries'

const AppHeader = () => {
    const { t } = useTranslation()
    const { user, signOut } = useAuth()
    const [priorities, dispatch] = usePriorities()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [navOpen, setNavOpen] = useState(false)
    const [filteredPriorities, setFilteredPriorities] = useState([])
    const [subsMenus, setSubMenus] = useState({
        creations: false,
        reports: false,
        bonuses: false,
        profile: false,
    })

    const [prioritiesQuery] = useQuery({
        query: GetPriorities,
        requestPolicy: 'network-only',
    })

    useEffect(() => {
        if (prioritiesQuery.fetching || !prioritiesQuery.data?.getPriorities) return

        dispatch({ type: ReducerActions.Load, data: [...prioritiesQuery.data.getPriorities] })
    }, [prioritiesQuery.fetching])

    useEffect(() => {
        if (!priorities?.length) {
            setFilteredPriorities([])
            return
        }

        setFilteredPriorities(priorities.filter(i => !i.assigneeId))
    }, [priorities])

    const toggleMenu = () => setNavOpen(!navOpen)
    const closeMenu = () => setNavOpen(false)

    const renderAdminMenu = () => (
        <>
            <div className={`nav-sub${subsMenus.material ? ' nav-sub--open' : ''}`}>
                <a
                    className={['/deals'].includes(pathname) ? 'active' : ''}
                    onClick={() => {
                        setSubMenus({ ...subsMenus, creations: !subsMenus.creations })
                        navigate('/deals')
                    }}
                >
                    { t('header.deals') }
                </a>
            </div>
            <Link onClick={closeMenu} className={pathname === '/bot' ? 'active' : ''} to={'/bot'}>
                { t('header.bot') }
            </Link>
            <Link onClick={closeMenu} className={pathname === '/users' ? 'active' : ''} to={'/users'}>
                { t('header.users') }
            </Link>
            <Link onClick={closeMenu} className={pathname === '/settings' ? 'active' : ''} to={'/settings'}>
                { t('header.settings') }
            </Link>
            <Link onClick={closeMenu} className={pathname === '/stats' ? 'active' : ''} to={'/stats'}>
                { t('header.stats') }
            </Link>
            <div className='nav-sub'>
                <div className={`nav-sub--label${filteredPriorities.length < 1 ? ' nav-sub--label-disabled' : ''}`}>
                    <span className='app-header--info-priorities'>{ 'Prioriteedid' }</span>
                    {
                        filteredPriorities.length > 0 ?
                        <span className='nav-sub--badge'>{ filteredPriorities.length }</span>
                        :
                        <></>
                    }
                </div>
                <div className='nav-sub--dropdown nav-sub--dropdown-priorities'>
                    <Priorities priorities={filteredPriorities} />
                </div>
            </div>
        </>
    )

    const renderUserMenu = () => (
        <>
            <div className='nav-sub'>
                <div className={`nav-sub--label${filteredPriorities.length < 1 ? ' nav-sub--label-disabled' : ''}`}>
                    <span className='app-header--info-priorities'>{ 'Prioriteedid' }</span>
                    {
                        filteredPriorities.length > 0 ?
                        <span className='nav-sub--badge'>{ filteredPriorities.length }</span>
                        :
                        <></>
                    }
                </div>
                <div className='nav-sub--dropdown nav-sub--dropdown-priorities'>
                    <Priorities priorities={filteredPriorities} />
                </div>
            </div>
            <div className={`nav-sub${subsMenus.reports ? ' nav-sub--open' : ''}`}>
                <a
                    className={['/profile'].includes(pathname) ? 'active' : ''}
                    onClick={() => {
                        setSubMenus({ ...subsMenus, profile: !subsMenus.profile })
                        navigate(`/profile`)
                    }}
                >
                    { t('header.user_settings') }
                </a>
            </div>
        </>
    )

    return (
        <header className={`app-header${navOpen ? ' nav-open' : ''}`}>
            <div className='app-header--inner'>
                <div className='brand'>
                    <div onClick={() => navigate('/')} className='brand'>
                        <span>{ 'lepalaane' }</span>
                    </div>
                </div>
                <nav>
                    {
                        user?.role === UserRoles.Admin ?
                        renderAdminMenu()
                        :
                        renderUserMenu()
                    }
                    {
                        user &&
                        <>
                            <div className='nav-sub'>
                                <div className='app-header--info'>
                                    <span className='app-header--info-name'>{ `${(user.name)?.toLowerCase()}` }</span>
                                </div>
                                {
                                    user?.role === UserRoles.Admin ?
                                    <div className='nav-sub--dropdown'>
                                        <Link to={'/profile'}>
                                            { t('header.profile') }
                                        </Link>
                                    </div>
                                    :
                                    <></>
                                }
                            </div>
                            <div className='app-header--actions'>
                                <Button
                                    className={'btn-small'}
                                    label={t('header.sign_out')}
                                    onClick={signOut}
                                />
                            </div>
                        </>
                    }
                </nav>
                <div className="menu-btn" onClick={toggleMenu}>
                    <div></div>
                </div>
            </div>
        </header>
    )
}

export default AppHeader