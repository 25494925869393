const GetUsers = `
    query getUsers ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
        getUsers (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
            id
            name
            role
            email
        }
    }
`

const GetBotItems = `
    query getBotItems ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
        getBotItems (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
            id
            name
            pid
            createdAt
            properties {
                id
                propertyId
                area
                unit
                address
            }
        }
    }
`

const GetContacts = `
    query getContacts ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
        getContacts (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
            id
            name
            phone
            pid
            email
        }
    }
`

const GetDeals = `
    query getDeals ($page: Int!, $limit: Int!, $orderBy: String, $sort: String, $extraFilters: FilterInput) {
        getDeals (page: $page, limit: $limit, orderBy: $orderBy, sort: $sort, extraFilters: $extraFilters) {
            id
            name
            createdAt
            updatedAt
            status {
                id
                label
            }
            adminStatus {
                id
                label
            }
            creator {
                id
                name
            }
            assignee {
                id
                name
            }
            comments {
                id
                updatedAt
                content
                creator {
                    id
                    name
                }
            }
            properties {
                id
                property {
                    id
                    propertyId
                    size
                    sizeUnit
                    type
                    address
                    municipality
                    city
                    county
                    price
                    status {
                        id
                        label
                        color
                        priority
                    }
                }
            }
        }
    }
`

const GetLandInfo = `
    query getLandInfo ($propertyId: String!) {
        getLandInfo (propertyId: $propertyId) {
            address
            county
            city
            size
            sizeUnit
            municipality
        }
    }
`

const GetDeal = `
    query getDeal ($id: Int!) {
        getDeal (id: $id) {
            id
            name
            createdAt
            updatedAt
            status {
                id
                label
            }
            adminStatus {
                id
                label
            }
            creator {
                id
                name
                role
            }
            properties {
                id
                property {
                    id
                    propertyId
                    size
                    sizeUnit
                    type
                    address
                    city
                    county
                    municipality
                    price
                    status {
                        id
                        label
                        color
                        priority
                    }
                }
            }
            contacts {
                id
                main
                contact {
                    id
                    name
                    pid
                    email
                    phone
                }
            }
            priorities {
                id
                complete
                creator {
                    id
                    name
                }
                assignee {
                    id
                    name
                }
                createdAt
                updatedAt
            }
            assignee {
                id
                name
                role
            }
            comments {
                id
                content
                createdAt
                updatedAt
                creatorId
                creator {
                    id
                    name
                }
            }
        }
    }
`

const GetStatuses = `
    query getStatuses ($type: String) {
        getStatuses (type: $type) {
            id
            label
            color
            priority
            type
            createdAt
            updatedAt
        }
    }
`

const GetFilterViews = `
    query getFilterViews {
        getFilterViews {
            id
            label
            content
        }
    }
`

const GetStats = `
    query getStats ($start: Date!, $end: Date!, $status: Int!) {
        getStats (start: $start, end: $end, status: $status) {
            value
            label
        }
    }
`

const GetTasks = `
    query getTasks {
        getTasks {
            id
            type
            createdAt
            updatedAt
            description
            creator {
                id
                name
            }
            error
            stopped
            end
        }
    }
`

const CheckBot = `
    query checkBot {
        checkBot
    }
`

const GetPriorities = `
    query getPriorities {
        getPriorities {
            id
            complete
            creator {
                id
                name
            }
            assigneeId
            createdAt
            updatedAt
            deal {
                id
                name
            }
        }
    }
`

const GetDealPriority = `
    query getDealPriority ($dealId: Int!) {
        getDealPriority (dealId: $dealId) {
            id
            complete
            creator {
                id
                name
            }
            assigneeId
            createdAt
            updatedAt
            deal {
                id
                name
            }
        }
    }
`

const GetContact = `
    query getContact ($pid: String, $email: String) {
        getContact (pid: $pid, email: $email) {
            id
            name
            phone
            pid
            email
            deals {
                deal {
                    id
                    name
                }
            }
        }
    }
`

const GetPersonExists = `
    query getPersonExists ($pid: String, $email: String, $dealId: Int!) {
        getPersonExists (pid: $pid, email: $email, dealId: $dealId)
    }
`

const GetPropertyExists = `
    query getPropertyExists ($propertyId: String!, $dealId: Int!) {
        getPropertyExists (propertyId: $propertyId, dealId: $dealId) {
            id
            propertyId
            size
            sizeUnit
            type
            address
            city
            county
            municipality
            price
            status {
                id
                label
                color
                priority
            }
            deals {
                deal {
                    id
                    name
                }
            }
        }
    }
`

const GetPersonProperties = `
    query getPersonProperties ($pid: [String!]) {
        getPersonProperties (pid: $pid) {
            pid
            properties {
                propertyId
                size
                sizeUnit
                type
                address
                city
                county
                municipality
            }
        }
    }
`

export {
    GetUsers,
    GetDeals,
    GetDeal,
    GetLandInfo,
    GetContacts,
    GetStatuses,
    GetFilterViews,
    GetStats,
    GetTasks,
    CheckBot,
    GetBotItems,
    GetPriorities,
    GetDealPriority,
    GetContact,
    GetPropertyExists,
    GetPersonProperties,
    GetPersonExists,
}