import { useSubscription } from 'urql'
import { DealAdded, DealAddedToUser, DealRemoved, DealRemovedFromUser, DealUpdated, PriorityAdded, PriorityRemoved, PriorityUpdated } from '../graphql/subscriptions'
import { ReducerActions, UserRoles } from '../util/consts'
import { useDealSubscription } from '../providers/deal'
import { usePriorities } from '../providers/priorities'

const DealSubscriptions = ({
    user,
}) => {

    const [, dispatch] = useDealSubscription()
    const [, priorityDispatch] = usePriorities()

    useSubscription(
        {
            query: DealAdded,
        },
        (prev, data) => {
            if (!data?.dealAdded) return
            dispatch({
                type: ReducerActions.Add,
                data: data.dealAdded,
            })
        },
    )

    useSubscription(
        {
            query: DealUpdated,
        },
        (prev, data) => {
            if (!data?.dealUpdated) return
            dispatch({
                type: ReducerActions.Update,
                data: data.dealUpdated,
            })
        },
    )

    useSubscription(
        {
            pause: user?.role === UserRoles.Admin,
            query: DealRemovedFromUser,
        },
        (prev, data) => {
            if (!data?.dealRemovedFromUser) return
            dispatch({
                type: ReducerActions.Delete,
                data: data.dealRemovedFromUser,
            })
        },
    )

    useSubscription(
        {
            pause: user?.role === UserRoles.Admin,
            query: DealAddedToUser,
        },
        (prev, data) => {
            if (!data?.dealAddedToUser) return
            dispatch({
                type: ReducerActions.Add,
                data: data.dealAddedToUser,
            })
        },
    )

    useSubscription(
        {
            query: DealRemoved,
            pause: !user,
        },
        (prev, data) => {
            if (!data?.dealRemoved) return
            dispatch({
                type: ReducerActions.Delete,
                data: data.dealRemoved,
            })
        },
    )

    useSubscription(
        {
            query: PriorityRemoved,
        },
        (prev, data) => {
            if (!data?.priorityRemoved) return
            priorityDispatch({
                type: ReducerActions.Delete,
                data: data.priorityRemoved,
            })
        },
    )

    useSubscription(
        {
            query: PriorityAdded,
        },
        (prev, data) => {
            if (!data?.priorityAdded) return

            priorityDispatch({
                type: ReducerActions.Add,
                data: data.priorityAdded,
            })
        },
    )

    useSubscription(
        {
            query: PriorityUpdated,
        },
        (prev, data) => {
            if (!data?.priorityUpdated) return
            priorityDispatch({
                type: ReducerActions.Update,
                data: data.priorityUpdated,
            })
        },
    )
}

export default DealSubscriptions