import React, { useEffect } from 'react'
import { useQuery } from 'urql'
import { CheckBot } from '../graphql/queries'

const BotStatus = () => {

    const [, refetch] = useQuery({
        pause: true,
        query: CheckBot,
        requestPolicy: 'network-only',
    })

    useEffect(() => {
        const interval = setInterval(() => {
            refetch()
        }, 2000)

        return () => clearInterval(interval)
    }, [])
}

export default BotStatus