import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { ViewColumns } from '../util/consts'

const initialState = {
    columns: ViewColumns,
}

const storeData = (value) => {
    const jsonValue = JSON.stringify(value)
    localStorage.setItem('view', jsonValue)
}

const getData = async () => {
    const jsonValue = localStorage.getItem('view')
    return jsonValue != null ? JSON.parse(jsonValue) : null
}

const ViewContext = createContext([{}, function () { }])

const ViewProvider = ({ reducer, children, loaded }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        (async () => {
            const loadedState = await getData()

            if (loadedState) {
                dispatch({ type: 'HYDRATE', data: {
                    ...loadedState,
                } })
            }

            if (loaded) loaded()
        })()
    }, [])

    useEffect(() => {
        if (state === initialState) return
        storeData(state)
    }, [state])


    return (
        <ViewContext.Provider value={[state, dispatch]}>
            {children}
        </ViewContext.Provider>
    )
}

const useView = () => useContext(ViewContext)

export {
    ViewContext,
    ViewProvider,
    useView,
}