import React, { useEffect, useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { GetStats, GetStatuses } from '../../graphql/queries'
import { Chart as ChartJS } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Bar } from 'react-chartjs-2'
import DateInput from '../../components/DateInput'
import { parseDate } from '../../util/format'
import SelectInput from '../../components/SelectInput'
import { useQuery } from 'urql'
import { ChartColors } from '../../util/consts'

const initialResult = {
    labels: [],
    data: [],
}

const StatisticsScreen = () => {

    const { t } = useTranslation()
    const chartRef = useRef()
    const [start, setStart] = useState(new Date(new Date().getFullYear(), 0, 1))
    const [end, setEnd] = useState(new Date(new Date().getFullYear(), 11, 31))
    const [statuses, setStatuses] = useState([])
    const [status, setStatus] = useState(null)
    const [result, setResult] = useState(initialResult)

    const [statusQuery] = useQuery({
        query: GetStatuses,
    })

    const [statsQuery] = useQuery({
        requestPolicy: 'network-only',
        query: GetStats,
        variables: {
            start,
            end,
            status,
        },
    })

    useEffect(() => {
        if (statusQuery?.fetching) return

        setStatuses(statusQuery.data.getStatuses || [])
    }, [statusQuery?.fetching, statusQuery?.data])

    useEffect(() => {
        if (statsQuery?.fetching) return

        if (!statsQuery?.data?.getStats) {
            setResult(initialResult)
            return
        }

        setResult({
            labels: statsQuery?.data?.getStats.map(x => x.label),
            data: statsQuery?.data?.getStats.map(x => x.value),
        })
    }, [statsQuery?.fetching, statsQuery?.data])

    useEffect(() => {
        chartRef.current?.update()
    }, [result])

    return (
        <div className='stats'>
            <div className='inner'>
                <div className='inner-title'>
                    <DateInput
                        label={t('Algus')}
                        value={start ? new Date(start) : null}
                        onChange={(val) => setStart(parseDate(val))}
                    />
                    <DateInput
                        label={t('Lõpp')}
                        value={end ? new Date(end) : null}
                        onChange={(val) => setEnd(parseDate(val))}
                    />
                    <SelectInput
                        label={t('Staatus')}
                        options={statuses?.filter(x => x.type === 'DEAL')}
                        value={statuses?.find(x => x.id === status)}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.label}
                        onChange={(option) => setStatus(option.id)}
                    />
                </div>

                {
                    result &&
                    <Bar
                        ref={chartRef}
                        datasetIdKey='1'
                        options={{
                            plugins: {
                                datalabels: {
                                    anchor: 'end',
                                    align: 'top',
                                    formatter: function(value) {
                                        return value ? Number(parseFloat(value).toFixed(2)).toLocaleString('et') : ''
                                    },
                                },
                            },
                        }}
                        plugins={[ChartDataLabels]}
                        data={{
                            labels: result.labels,
                            datasets: [
                                {
                                    id: 1,
                                    label: statuses.find(x => x.value === status) ? t(`deal_status.${statuses.find(x => x.value === status)?.label}`) : '',
                                    data: result.data,
                                    backgroundColor: ChartColors[0],
                                },
                            ],
                        }}
                    />
                }
            </div>
        </div>
    )
}

export default StatisticsScreen