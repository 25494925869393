import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'urql'
import Button from './Button'
import Modal from './Modal'
import { Responses, UserRoles } from '../util/consts'
import { CreateDeal } from '../graphql/mutations'
import BoxNotification from './BoxNotification'
import PropertyForm from './PropertyForm'
import ContactForm from './ContactForm'
import Input from './Input'
import SelectInput from './SelectInput'
import Textarea from './Textarea'
import { GetStatuses, GetUsers } from '../graphql/queries'
import { useAuth } from '../providers/auth'
import PersonProperties from './PersonProperties'

const initialState = {
    name: '',
    assignedUser: null,
    comment: '',
    status: '',
    adminStatus: '',
}

const initialErrorsState = {
    name: null,
    generic: null,
}

const AddDealModal = ({
    show,
    close,
    onSuccess,
    data,
}) => {

    const { t } = useTranslation()
    const { user } = useAuth()
    const [item, setItem] = useState(initialState)
    const [properties, setProperties] = useState([])
    const [contacts, setContacts] = useState([])
    const [errors, setErrors] = useState(initialErrorsState)
    const [, addDeal] = useMutation(CreateDeal)
    const [loading, setLoading] = useState(false)
    const [loadingProperties, setLoadingProperties] = useState(false)
    const [users, setUsers] = useState([])
    const [statuses, setStatuses] = useState([])

    const [usersQuery] = useQuery({
        query: GetUsers,
        variables: {
            page: 0,
            limit: 100,
            orderBy: 'name',
        },
    })

    const [statusQuery] = useQuery({
        query: GetStatuses,
    })

    useEffect(() => {
        if (!data) return
        
        if (data.properties) setProperties(data.properties)
        if (data.contacts) setContacts(data.contacts)
    }, [data])

    console.log('addealmodal properties', properties)

    useEffect(() => {
        if (usersQuery?.fetching) return

        setUsers(usersQuery.data.getUsers || [])
    }, [usersQuery?.fetching, usersQuery?.data])

    useEffect(() => {
        if (statusQuery?.fetching) return

        setStatuses(statusQuery.data.getStatuses || [])
    }, [statusQuery?.fetching, statusQuery?.data])

    const hasInputErrors = () => {
        let hasErrors = false
        const errorState = {
            ...initialErrorsState,
        }

        if (!properties?.length || properties.some(x => !x.propertyId)) {
            hasErrors = true
            errorState.properties = t('deals.fill_property')
        }

        if (!contacts?.length || contacts.some(x => !x.name)) {
            hasErrors = true
            errorState.contacts = t('deals.fill_one_contact')
        }

        setErrors({
            ...errorState,
        })
        return hasErrors
    }

    const handleAdd = async () => {
        if (hasInputErrors()) return
        setLoading(true)

        try {
            const input = {
                properties: properties.map(x => ({
                    ...x,
                    status: x.status ? x.status.id : undefined,
                    size: x.size ? parseFloat(x.size) : undefined,
                })),
                contacts,
                ...(item.comment && {
                    comments: {
                        content: item.comment,
                    },
                }),
            }

            if (item.assignedUser) input.assignedUser = item.assignedUser.id
            if (item.name) input.name = item.name
            if (item.status?.id) input.status = item.status.id
            if (item.adminStatus?.id) input.adminStatus = item.adminStatus.id
            if (!item.name) input.name = `${contacts[0].name} - ${properties[0].address}`

            const { data } = await addDeal({
                input,
            })

            if (!data?.createDeal || data.createDeal !== Responses.Success) {
                setErrors({
                    ...initialErrorsState,
                    generic: data.createDeal === Responses.Exists ? t('deals.user_exists') : t('deals.add_error'),
                })
                return
            }

            if (onSuccess) {
                onSuccess(data.createDeal)
                handleClose()
            }
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('deals.add_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setErrors(initialErrorsState)
        setItem(initialState)
        setProperties([])
        setContacts([])
        setLoadingProperties(false)
    }

    const handleUpdate = (field, value) => {
        if (field === 'properties') {
            setProperties([...value])
            setErrors({
                ...errors,
                properties: null,
            })
            return
        }
        if (field === 'contacts') {
            setContacts([...value])
            console.log(value)
            setErrors({
                ...errors,
                contacts: null,
            })
            return
        }
    }

    const handlePropertiesResult = (result) => {
        if (!result) {
            setLoadingProperties(false)
            return
        }

        let list = []
        result.forEach(x => {
            list = [...list, ...x.properties.map(i => ({
                ...i,
                __typename: undefined,
            }))]
        })

        setProperties(list)

        setLoadingProperties(false)
    }

    return (
        <Modal
            className={'modal-deal'}
            show={show}
            close={handleClose}
            title={ t('deals.add_title') }
            renderActions={
                <div className='modal-actions'>
                    <PersonProperties
                        persons={contacts}
                        onStartLoading={() => setLoadingProperties(true)}
                        onResult={handlePropertiesResult}
                    />
                    <Button
                        onClick={handleAdd}
                        label={t('deals.save_deal')}
                        loading={loading}
                    />
                </div>
            }
        >
            <div className='modal-deal--main'>
                <Input
                    label={t('deals.name')}
                    value={item?.name}
                    onChange={e => setItem({...item, name: e.target.value })}
                />
                <SelectInput
                    label={ t('deals.assign_user') }
                    options={users}
                    value={item?.assignedUser}
                    getOptionLabel={(option) => option.name}
                    getOptionValue={(option) => option.id}
                    onChange={(option) => setItem({...item, assignedUser: option })}
                />
                <SelectInput
                    label={ t('deals.status') }
                    options={statuses?.filter(i => i.type === 'DEAL')}
                    value={item?.status}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.id}
                    onChange={(option) => setItem({...item, status: option })}
                    isClearable={true}
                />
                {
                    user?.role === UserRoles.Admin ?
                    <SelectInput
                        label={ t('deals.admin_status') }
                        options={statuses?.filter(i => i.type === 'ADMIN')}
                        value={item?.adminStatus}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.id}
                        onChange={(option) => setItem({...item, adminStatus: option })}
                        isClearable={true}
                    />
                    :
                    <></>
                }
            </div>
            <div className='modal-deal--info'>
                {
                    loadingProperties ?
                    <div className='properties-placeholder'>
                        <h4>{ t('deals.properties') }</h4>
                        <div className='card'>
                            <div className='card-image'>
                                <div className='load-wrapper'>
                                    <div className='activity'></div>
                                </div>
                            </div>
                            <div className='card-content'>
                                <div className='card-text'>
                                    <div className='load-wrapper'>
                                        <div className='activity'></div>
                                    </div>
                                </div>
                            </div>
                            <div className='card-image'>
                                <div className='load-wrapper'>
                                    <div className='activity'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <PropertyForm
                        onChange={(data) => handleUpdate('properties', data)}
                        error={errors.properties}
                        initialData={properties}
                    />
                }
                <ContactForm
                    onChange={(data) => handleUpdate('contacts', data)}
                    error={errors.contacts}
                    initialData={contacts}
                />
            </div>

            <Textarea
                label={t('deals.comment')}
                onChange={e => setItem({...item, comment: e.target.value })}
                value={item?.comment}
            />

            {
                errors?.generic ?
                <BoxNotification
                    message={errors.generic}
                    type={'error'}
                />
                :
                <></>
            }
        </Modal>
    )
}

AddDealModal.propTypes = {
    show: PropTypes.bool,
    close: PropTypes.func,
    onSuccess: PropTypes.func,
}

export default AddDealModal