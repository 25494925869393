import React, { createContext, useContext, useReducer } from 'react'

const initialState = {
    addedResult: null,
    removedResult: null,
    updatedResult: null,
}

const DealContext = createContext([{}, function () { }])

const DealProvider = ({ reducer, children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <DealContext.Provider value={[state, dispatch]}>
            {children}
        </DealContext.Provider>
    )
}

const useDealSubscription = () => useContext(DealContext)

export {
    DealContext,
    DealProvider,
    useDealSubscription,
}