import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { GetLandInfo, GetStatuses } from '../graphql/queries'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'urql'
import Input from './Input'
import Button from './Button'
import PlusIcon from './icons/plus'
import BoxNotification from './BoxNotification'
import SelectInput from './SelectInput'
import { PropertyType, SizeUnit } from '../util/consts'
import ChevronBoldIcon from './icons/chevron-bold'
import DeleteIcon from './icons/delete'
import ExistingProperty from './ExistingProperty'

const propertyTypes = Object.entries(PropertyType).map(([, value]) => ({
    value,
    label: `property_type.${value}`,
}))

const sizeUnits = Object.entries(SizeUnit).map(([, value]) => ({
    value,
    label: `${value}`,
}))

const initialProperty = {
    propertyId: '',
    address: '',
    county: '',
    city: '',
    type: '',
    size: '',
    sizeUnit: '',
    municipality: '',
}

const PropertyInput = ({
    data,
    onChange,
    onRemove,
    collapsible,
    index,
    dealId,
}) => {
    const { t } = useTranslation()
    const [item, setItem] = useState(data || initialProperty)
    const [isOpen, setIsOpen] = useState(false)
    const [propertyId, setPropertyId] = useState(null)
    const [statuses, setStatuses] = useState([])
    const [landQuery] = useQuery({
        query: GetLandInfo,
        requestPolicy: 'network-only',
        variables: {
            propertyId,
        },
    })
    const [statusQuery] = useQuery({
        query: GetStatuses,
        variables: {
            type: 'PROPERTY',
        },
        requestPolicy: 'network-only',
    })

    useEffect(() => {
        if (statusQuery?.fetching) return

        setStatuses(statusQuery?.data?.getStatuses || [])
    }, [statusQuery?.fetching, statusQuery?.data])

    useEffect(() => {
        if (landQuery?.fetching) return

        if (!landQuery?.data?.getLandInfo) {
            const newItem = {
                ...initialProperty,
                ...data,
                propertyId: item.propertyId,
            }
            setItem(newItem)
            if (onChange) onChange(newItem)
            return
        }

        delete landQuery.data.getLandInfo.__typename

        const newItem = {
            ...item,
            ...landQuery.data.getLandInfo,
        }

        setItem(newItem)
        if (onChange) onChange(newItem)
    }, [landQuery?.fetching, landQuery?.data])

    const setField = (field, value) => {
        const newItem = {
            ...item,
            [field]: value,
        }
        setItem(newItem)
        if (onChange) onChange(newItem)
    }

    const handleKeyPress = (e) => {
        if (e.key !== 'Enter') return

        if (!item?.propertyId) {
            setItem(initialProperty)
            setPropertyId(null)
            return
        }

        setPropertyId(item.propertyId)
    }

    const handleBlur = () => {
        if (!item?.propertyId || item.propertyId.length < 5) return
        setPropertyId(item.propertyId)
    }

    const renderFields = () => (
        <div className={'property'}>
            <div className='alert-input'>
                <Input
                    label={ t('deals.property_id') }
                    placeholder={ t('deals.property_id') }
                    value={item.propertyId}
                    onChange={(e) => setField('propertyId', e.target.value)}
                    onKeyUp={handleKeyPress}
                    onBlur={handleBlur}
                />
                <ExistingProperty propertyId={item?.propertyId} dealId={dealId} />
            </div>
            <Input
                label={ t('deals.address') }
                placeholder={ t('deals.address') }
                value={item.address}
                onChange={(e) => setField('address', e.target.value)}
            />
            <Input
                label={ t('deals.county') }
                placeholder={ t('deals.county') }
                value={item.county}
                onChange={(e) => setField('county', e.target.value)}
            />
            <Input
                label={ t('deals.municipality') }
                placeholder={ t('deals.municipality') }
                value={item.municipality}
                onChange={(e) => setField('municipality', e.target.value)}
            />
            <Input
                label={ t('deals.city') }
                placeholder={ t('deals.city') }
                value={item.city}
                onChange={(e) => setField('city', e.target.value)}
            />
            <div className='size-row'>
                <Input
                    label={ t('deals.property_size') }
                    placeholder={ t('deals.property_size') }
                    value={item.size}
                    onChange={(e) => setField('size', e.target.value)}
                />
                <SelectInput
                    label={t('deals.property_size_unit')}
                    options={sizeUnits}
                    value={sizeUnits.find(x => x.value === item.sizeUnit)}
                    getOptionLabel={(option) => t(option.label)}
                    onChange={(option) => setField('sizeUnit', option.value)}
                    menuPlacement={'top'}
                />
            </div>
            <Input
                label={ t('deals.property_price') }
                placeholder={ t('deals.property_price') }
                value={item.price}
                type={'number'}
                onChange={(e) => setField('price', e.target.value)}
            />
            <SelectInput
                label={t('deals.property_type')}
                options={propertyTypes}
                value={propertyTypes.find(x => x.value === item.type)}
                getOptionLabel={(option) => t(option.label)}
                onChange={(option) => setField('type', option?.value)}
                menuPlacement={'top'}
                isClearable
            />
            <SelectInput
                label={t('deals.property_status')}
                className={'status-select'}
                options={statuses}
                value={item.status}
                noOptionsMessage={() => t('deals.no_property_statuses')}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.id}
                onChange={(option) => setField('status', option)}
                isClearable={true}
            />
            {
                index === 0 ?
                <></>
                :
                <Button
                    label={t('deals.remove_row')}
                    className={'btn-remove'}
                    icon={<DeleteIcon />}
                    onClick={onRemove}
                />
            }
        </div>
    )

    if (!item) return <></>
    
    return collapsible ?
        <div className={`property-collapsible`}>
            <div
                className='property-collapsible--header'
                onClick={() => setIsOpen(!isOpen)}
            >
                <span>
                    {
                        item.status ?
                        <div className='property-status' style={{ backgroundColor: item.status.color }}></div>
                        :
                        <></>
                    }
                    <span>{ item.propertyId }</span>
                </span>
                <ChevronBoldIcon />
            </div>
            {
                isOpen ?
                renderFields()
                :
                <></>
            }
        </div>
        :
        renderFields()
}

PropertyInput.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
}

const PropertyForm = ({
    initialData,
    onChange,
    error,
    collapsible,
    dealId,
}) => {
    const { t } = useTranslation()
    const [items, setItems] = useState(initialData?.length ? initialData : [initialProperty])

    useEffect(() => {
        if (!initialData) return

        setItems(initialData)
    }, [initialData])

    useEffect(() => {
        if (onChange) onChange(items)
    }, [])

    const handleAddProperty = () => {
        const newItems = [...items, initialProperty]
        setItems(newItems)

        if (onChange) onChange(newItems)
    }

    const updateItems = (item, index) => {
        const newItems = [...items]
        newItems[index] = {
            ...item,
            price: item?.price?.length ? parseFloat(item.price) : undefined,
        }
        setItems(newItems)

        if (onChange) onChange(newItems)
    }

    const handleRemove = (index) => {
        if (index === 0) return

        const newItems = items.filter((_, i) => i !== index)
        setItems(newItems)

        if (onChange) onChange(newItems)
    }
    
    return (
        <div className={'properties'}>
            <h4>{ t('deals.properties') }</h4>
            {
                items.map((item, index) =>
                    <PropertyInput
                        key={`property-${index}`}
                        data={item.property ? item.property : item}
                        onChange={(data) => updateItems(data, index)}
                        onRemove={() => handleRemove(index)}
                        collapsible={collapsible}
                        index={index}
                        dealId={dealId}
                    />
                )
            }
            {
                error ?
                <BoxNotification
                    message={error}
                    type={'error'}
                />
                :
                <></>
            }
            <Button
                label={t('deals.add_property')}
                icon={<PlusIcon />}
                onClick={handleAddProperty}
            />
        </div>
    )
}

PropertyForm.propTypes = {
    onChange: PropTypes.func,
}

export default PropertyForm