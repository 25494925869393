import React, { createContext, useContext, useEffect, useReducer } from 'react'

const initialState = []

const storeData = (value) => {
    const jsonValue = JSON.stringify(value)
    localStorage.setItem('priorities', jsonValue)
}

const getData = async () => {
    const jsonValue = localStorage.getItem('priorities')
    return jsonValue != null ? JSON.parse(jsonValue) : null
}

const PrioritiesContext = createContext([{}, function () { }])

const PrioritiesProvider = ({ reducer, children, loaded }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        (async () => {
            const loadedState = await getData()

            if (loadedState) {
                dispatch({ type: 'HYDRATE', data: {
                    ...loadedState,
                } })
            }

            if (loaded) loaded()
        })()
    }, [])

    useEffect(() => {
        if (state === initialState) return
        storeData(state)
    }, [state])

    return (
        <PrioritiesContext.Provider value={[state, dispatch]}>
            {children}
        </PrioritiesContext.Provider>
    )
}

const usePriorities = () => useContext(PrioritiesContext)

export {
    PrioritiesContext,
    PrioritiesProvider,
    usePriorities,
}