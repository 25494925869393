const UserRoles = {
    Admin: 'ADMIN',
    Employee: 'EMPLOYEE',
}

const PropertyType = {
    Property: 'PROPERTY',
    Harvest: 'HARVEST',
    Field: 'FIELD',
    Other: 'OTHER',
    Undefined: '---',
}

const Priority = {
    High: 0,
    Medium: 1,
    Low: 2,
}

const Tags = {}

const DealStatus = {
    PriceDone: 'PRICE_DONE',
    PriceSent: 'PRICE_SENT',
    NoFeedback: 'NO_FEEDBACK',
    NoResponse: 'NO_RESPONSE',
    InProgress: 'IN_PROGRESS',
    PriceLow: 'PRICE_LOW',
    Closed: 'CLOSED',
    Abandoned: 'ABANDONED',
    BackedOut: 'BACKED_OUT',
    WaitingPrice: 'WAITING_PRICE',
    Won: 'WON',
    Lost: 'LOST',
    Undefined: 'UNDEFINED',
}

const StatusType = {
    Deal: 'DEAL',
    Property: 'PROPERTY',
    Admin: 'ADMIN',
}

const Responses = {
    Failed: 'FAIL',
    Success: 'SUCCESS',
    Exists: 'EXISTS',
}

const ReducerActions = {
    Add: 'ADD',
    Update: 'UPDATE',
    Delete: 'DELETE',
    Replace: 'REPLACE',
    Load: 'LOAD',
}

const ChartColors = ['#4dd45f', '#7eb0d5', '#b2e061', '#bd7ebe', '#ffb55a', '#ffee65', '#beb9db', '#fdcce5', '#8bd3c7']

const TaskType = {
    PropertyImport: 'PROPERTY_IMPORT',
    BotScan: 'BOT_SCAN',
}

const Counties = [
    'Harju maakond',
    'Hiiu maakond',
    'Ida-Viru maakond',
    'Jõgeva maakond',
    'Järva maakond',
    'Lääne maakond',
    'Lääne-Viru maakond',
    'Põlva maakond',
    'Pärnu maakond',
    'Rapla maakond',
    'Saare maakond',
    'Tartu maakond',
    'Valga maakond',
    'Viljandi maakond',
    'Võru maakond',
]

const SizeUnit = {
    Ha: 'ha',
    M2: 'm²'
}

const Actions = {
    Hydrate: 'HYDRATE',
    SetView: 'SET_VIEW',
}

const ViewColumns = [
    {
        value: 'id',
        label: 'ID',
        index: 0,
    },
    {
        value: 'name',
        label: 'Nimetus',
        index: 1,
    },
    {
        value: 'updatedAt',
        label: 'Muudetud',
        index: 2,
    },
    {
        value: 'status',
        label: 'Staatus',
        index: 3,
    },
    {
        value: 'assignee',
        label: 'Maakler',
        index: 4,
    },
    {
        value: 'municipality',
        label: 'Vald',
        index: 5,
    },
    {
        value: 'properties',
        label: 'Katastrid',
        index: 6,
    },
    {
        value: 'comment',
        label: 'Kommentaar',
        index: 7,
    },
]

export {
    UserRoles,
    Tags,
    Responses,
    ReducerActions,
    PropertyType,
    DealStatus,
    Priority,
    ChartColors,
    TaskType,
    Counties,
    StatusType,
    SizeUnit,
    Actions,
    ViewColumns,
}