import React from 'react'
import PropTypes from 'prop-types'

const ScreenHeader = ({
    title,
    actions,
}) => (
    <div className='app-content--header'>
        <h3>{ title }</h3>
        {
            actions ?
            <div className='app-content--header-actions'>
                { actions() }
            </div>
            :
            <></>
        }
    </div>
)

ScreenHeader.propTypes = {
    title: PropTypes.string,
    actions: PropTypes.elementType,
}

export default ScreenHeader