import React, { useState, useEffect, useContext, createContext } from 'react'
import PropTypes from 'prop-types'
import jwtDecode from 'jwt-decode'
import { useNavigate } from 'react-router-dom'
import { UserRoles } from '../util/consts'

const authContext = createContext()

const useAuth = () => useContext(authContext)

const useProvideAuth = () => {
  const [user, setUser] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    validate()
  }, [])

  useEffect(() => {
    if (user?.role === UserRoles.Admin) {
      document.body.classList.add('admin')
    } else {
      document.body.classList.remove('admin')
    }
  }, [user])

  const signOut = () => {
    localStorage.removeItem('token')

    setUser(null)
    setLoggedIn(false)
    navigate('/login')
  }

  const validate = async () => {
    const token = localStorage.getItem('token')

    if (!token) {
      navigate('/login')
      setLoaded(true)
      return
    }

    const userData = jwtDecode(token)

    setUser({
      ...userData,
    })

    setLoggedIn(true)
    setLoaded(true)
  }

  const signIn = (token) => {
    if (!token) return

    localStorage.setItem('token', token)

    const userData = token ? jwtDecode(token) : null

    setUser(userData)
    setLoggedIn(true)

    navigate('/deals')
  }

  return {
    user,
    loggedIn,
    signIn,
    signOut,
    loaded,
  }
}

const ProvideAuth = ({ children }) => {
    const auth = useProvideAuth()
  
    return <authContext.Provider
              value={ auth }
          >
              { children }
          </authContext.Provider>
}

ProvideAuth.propTypes = {
    children: PropTypes.element,
}

export {
    ProvideAuth,
    useAuth,
}