import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { GetTasks } from '../graphql/queries'
import { formatDate } from '../util/format'
import { useTranslation } from 'react-i18next'
import Button from './Button'
import { StopBot, StopTask } from '../graphql/mutations'
import { useMutation, useQuery, useSubscription } from 'urql'
import { TaskUpdated } from '../graphql/subscriptions'
import { Responses, TaskType } from '../util/consts'

const Tasks = forwardRef(({
    type,
    onTaskEnded,
}, ref) => {

    const { t } = useTranslation()
    const [tasksQuery, refetch] = useQuery({
        query: GetTasks,
        requestPolicy: 'network-only',
    })
    const [, stopTask] = useMutation(StopTask)
    const [, stopBot] = useMutation(StopBot)
    const [followedTasks, setFollowedTasks] = useState([])
    const [tasks, setTasks] = useState([])
    
    useSubscription(
        {
            pause: !followedTasks?.length,
            query: TaskUpdated,
        },
        (prev, data) => {
            console.log(data)
            if (!data.taskUpdated?.id) return

            const taskIndex = tasks.findIndex(x => x.id === data.taskUpdated.id)

            if (taskIndex < 0) return

            const tasksCopy = [...tasks]
            tasksCopy[taskIndex] = data.taskUpdated

            setTasks(tasksCopy)

            if (onTaskEnded && (data.taskUpdated?.end || data.taskUpdated?.error || data.taskUpdated?.stopped)) onTaskEnded({
                ...data.taskUpdated,
                taskType: TaskType.BotScan,
            })
        },
    )

    useEffect(() => {
        if (tasksQuery?.fetching) return

        if (tasksQuery?.data?.getTasks?.length) {
            setTasks(tasksQuery.data.getTasks)
            setFollowedTasks(tasksQuery.data.getTasks.map(t => t.id))
        }
    }, [tasksQuery?.fetching, tasksQuery?.data])

    useImperativeHandle(ref, () => ({
        refresh () {
            refetch()
        },
    }))

    const handleStopTask = async (id, type) => {
        try {
            let res = null

            if (type === TaskType.BotScan) {
                res = await stopBot()
                setTasks(tasks.filter(t => t.type !== TaskType.BotScan))
                
                return
            } else {
                res = await stopTask({
                    id: parseInt(id),
                })
            }

            if (res?.data?.stopTask === Responses.Success) {
                setTasks(tasks.filter(t => t.id !== id))
                refetch()
            }
        } catch (err) {
            console.log('handleStopTask: ', err)
        }
    }

    return (
        tasks?.length > 0 ?
        <div className='tasks'>
            <h5>{ t('tasks.title') }</h5>
            <div className='tasks-list'>
            {
                tasks.map(x =>
                    <div key={`tasks-${x.id}`} className='tasks-item'>
                        <span>{ t(`tasks.${x.type}`) }</span>
                        <span>{ x.description }</span>
                        <span>{ `${t('tasks.start')}: ${formatDate(x.createdAt, true)}` }</span>
                        {
                            !x.end ?
                            <Button
                                onClick={() => handleStopTask(x.id, x.type)}
                                label={x.stopped ? t('tasks.stopped') : t('tasks.stop')}
                                disabled={x.stopped}
                            />
                            :
                            <span>{ t('tasks.ended') }</span>
                        }
                    </div>    
                )
            }
            </div>
        </div>
        :
        <></>
    )
})

export default Tasks