import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'urql'
import { RemovePriority, SetPriority } from '../graphql/mutations'
import Checkbox from './Checkbox'
import { Responses } from '../util/consts'
import { GetDealPriority } from '../graphql/queries'

const SetPriorityButton = ({
    dealId,
}) => {

    const { t } = useTranslation()
    const [, setPriority] = useMutation(SetPriority)
    const [, removePriority] = useMutation(RemovePriority)
    const [checked, setChecked] = useState(false)
    const [loading, setLoading] = useState(false)
    const [item, setItem] = useState()

    const [priorityQuery, refetch] = useQuery({
        requestPolicy: 'network-only',
        paused: !dealId,
        query: GetDealPriority,
        variables: {
            dealId,
        },
    })

    useEffect(() => {
        console.log(priorityQuery)
        if (priorityQuery.fetching || !priorityQuery.data?.getDealPriority) {
            setItem(null)
            return
        }

        setItem(priorityQuery.data.getDealPriority)
    }, [priorityQuery])

    useEffect(() => {
        if (item) {
            setChecked(true)
            return
        }
        setChecked(false)
    }, [item])

    const handleSet = async () => {
        setLoading(true)

        try {
            if (checked) {
                const res = await removePriority({
                    ids: item.id,
                })

                if (res?.data?.removePriority === Responses.Success) {
                    setChecked(false)
                    refetch()
                }

                return
            }
            const res = await setPriority({
                ids: [dealId],
            })

            if (res?.data?.setPriority === Responses.Success) {
                setChecked(true)
                refetch()
            }
        } catch (err) {
            console.log('set priority error', err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <Checkbox
            label={t('deals.set_priority')}
            onChange={handleSet}
            value={checked}
            loading={loading}
        />
    )
}

export default SetPriorityButton