import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { ProvideAuth } from './providers/auth'
import ErrorBoundary from './components/ErrorBoundary'
import AppWrapper from './AppWrapper'
import { ViewProvider } from './providers/view'
import ViewReducer from './reducers/view'

const App = () => (
  <ErrorBoundary>
    <BrowserRouter>
      <ProvideAuth>
        <ViewProvider reducer={ViewReducer}>
          <AppWrapper />
        </ViewProvider>
      </ProvideAuth>
    </BrowserRouter>
  </ErrorBoundary>
)

export default App
