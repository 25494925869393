import React, { useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import Button from '../../components/Button'
import DateInput from '../../components/DateInput'
import { RemoveBotItem, StartBot } from '../../graphql/mutations'
import { formatDate, parseDate } from '../../util/format'
import { GetBotItems } from '../../graphql/queries'
import FilterTable from '../../components/FilterTable'
import ScreenHeader from '../../components/ScreenHeader'
import DeleteIcon from '../../components/icons/delete'
import { Counties, Responses, TaskType } from '../../util/consts'
import { useRef } from 'react'
import Tasks from '../../components/Tasks'
import AddDealModal from '../../components/AddDealModal'
import SelectInput from '../../components/SelectInput'
import BotStatus from '../../components/BotStatus'

const initialState = {
    from: '',
    to: '',
    county: '',
}

const countyOptions = [
    {
        value: '',
        label: 'Kõik',
    },
    ...Counties.map(county => ({
        value: county,
        label: county,
    }))
]

const includeFields = [
    {
        value: 'name',
        label: 'Nimi',
    },
    {
        value: 'pid',
        label: 'Isikukood',
    },
    {
        value: 'properties',
        label: 'Kinnistud',
    },
    {
        value: 'createdAt',
        label: 'Kuupäev',
    },
]

const fieldConditions = (row, field) => {
    switch (field) {
        case 'properties': {
            if (row.properties?.length) {
                return (
                    <div className='bot-properties'>
                        {
                            row.properties.map((property, index) => (
                                <div key={`bot-property-${index}-${row.pid}`} className='bot-property'>
                                    <div>{ `${property.propertyId} - ${property.address}` }</div>
                                    <div>{ `${property.area} ${property.unit}` }</div>
                                </div>
                            ))
                        }
                    </div>
                )
            }
            return row[field]
        }
        case 'createdAt':
            return formatDate(row[field], true)
        default:
            return row[field]
    }
}

const BotScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const tasksRef = useRef()
    const [inputs, setInputs] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRows, setSelectedRows] = useState([])
    const [, startBot] = useMutation(StartBot)
    const [, deleteItem] = useMutation(RemoveBotItem)

    const handleInputChange = (field, value) => {
        setInputs({
            ...inputs,
            [field]: value,
        })
    }

    const handleStart = async () => {
        if (!inputs.county || !inputs.from || !inputs.to) return

        setLoading(true)
        const { data } = await startBot({
            ...inputs,
            county: inputs.county.value,
        })

        if (data?.startBot === Responses.Success) {
            tasksRef.current?.refresh()
        }

        setLoading(false)
    }

    const handleRowClick = (selection) => {
        if (selectedRows.length === 1 && selectedRows[0] === selection) {
            setSelectedRows([])
            return
        }
        if (Array.isArray(selection)) {
            setSelectedRows(selection)
        } else {
            setSelectedRows([selection])
        }
    }

    const handleRowDoubleClick = (row) => {
        setSelectedRow(row)
    }

    const handleAddSuccess = () => {
        const id = selectedRow?.id

        setSelectedRow(null)
        if (id) handleDelete(id)
    }

    const handleDelete = async (id) => {
        try {
            const deleteRes = await deleteItem({
                ids: id ? [id] : selectedRows.map((row) => row.id),
            })
    
            if (
                !deleteRes?.data?.removeBotItem
                || deleteRes.data.removeBotItem !== Responses.Success
            ) return
    
            setSelectedRows([])
            tableRef.current?.refresh()
        } catch (err) {
            console.log(err)
        }
    }

    const handleTaskEnd = (task) => {
        if (task?.taskType === TaskType.BotScan) tableRef.current?.refresh()
    }

    const renderHeaderActions = () => (
        <>
            {
                selectedRows?.length ?
                <Button
                    label={t('deals.delete')}
                    icon={<DeleteIcon />}
                    onClick={() => handleDelete()}
                />
                :
                <></>
            }
        </>
    )

    console.log('selected row:', selectedRow)

    return (
        <div className='bot'>
            <ScreenHeader
                title={ t('bot.title') }
                actions={renderHeaderActions}
            />

            <div
                className={`filters-wrapper`}
            >
                <div className='filters'>
                    <DateInput
                        label={t('Algus')}
                        value={inputs.from ? new Date(inputs.from) : null}
                        onChange={(val) => handleInputChange('from', parseDate(val))}
                    />
                    <DateInput
                        label={t('Lõpp')}
                        value={inputs.to ? new Date(inputs.to) : null}
                        onChange={(val) => handleInputChange('to', parseDate(val))}
                    />
                    <SelectInput
                        value={inputs.county}
                        options={countyOptions}
                        onChange={(val) => handleInputChange('county', val)}
                        label={t('Maakond')}
                    />
                    <Button
                        label={t('bot.start')}
                        onClick={handleStart}
                        loading={loading}
                    />
                </div>
            </div>

            <Tasks ref={tasksRef} onTaskEnded={handleTaskEnd} />

            <FilterTable
                ref={tableRef}
                query={GetBotItems}
                queryKey='getBotItems'
                includeFields={includeFields}
                fieldConditions={fieldConditions}
                initialOrderBy='id'
                hideInputs={true}
                allowSelect={true}
                onRowDoubleClick={handleRowDoubleClick}
                onRowClick={handleRowClick}
                activeRows={selectedRows}
            />

            <AddDealModal
                show={selectedRow !== null}
                onSuccess={handleAddSuccess}
                close={() => setSelectedRow(null)}
                data={selectedRow?.id ? {
                    properties: selectedRow?.properties.map((property) => ({
                        propertyId: property.propertyId,
                        size: property.area,
                        sizeUnit: property.unit,
                    })),
                    contacts: [{
                        pid: selectedRow?.pid,
                        name: selectedRow?.name,
                    }],
                } : null}
            />

            <BotStatus />
        </div>
    )
}

export default BotScreen