import React from 'react'
import PropTypes from 'prop-types'
import ActivityIndicator from './ActivityIndicator'
import { Dots } from 'react-activity'

const Button = ({
  onClick,
  label,
  disabled,
  className,
  loading,
  icon,
}) => (
  <button
    className={`btn${className ? ` ${className}` : ''}${loading ? ` btn--loading` : ''}${icon ? ` btn--has-icon` : ''}`}
    onClick={onClick}
    disabled={disabled || loading}
  >
    {
      icon ?
      <div className='btn--icon'>{ icon }</div>
      :
      <></>
    }
    {
      label ?
      <span>{ label }</span>
      :
      <></>
    }
    {
      loading ?
      <Dots color='#49a175' speed={0.5} />
      :
      <></>
    }
  </button>
)

Button.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  icon: PropTypes.object,
}

export default Button