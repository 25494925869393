import React from 'react'
import { formatDate } from '../util/format'
import { useMutation } from 'urql'
import Button from './Button'
import { AssignPriority } from '../graphql/mutations'

const Priorities = ({
    priorities,
}) => {
    const [, assignPriority] = useMutation(AssignPriority)

    const assignToSelf = async (id) => {
        const res = await assignPriority({
            id: parseInt(id),
        })

        console.log('assignToSelf', res)
    }

    return (
        <div className='priorities'>
            {
                priorities?.length ?
                priorities.map(priority => (
                    <div key={priority.id} className='priority'>
                        <div className='priority-content'>
                            <h3>{priority.deal?.name}</h3>
                            <p>{priority.creator?.name}</p>
                            <p>{ formatDate(priority.createdAt, true) }</p>
                        </div>
                        <div className='priority-actions'>
                            <Button
                                label={'Võta endale'}
                                onClick={() => assignToSelf(priority.id)}
                            />
                        </div>
                    </div>
                ))
                :
                <></>
            }
        </div>
    )
}

export default Priorities