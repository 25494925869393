import { Actions } from '../util/consts'

const ViewReducer = (state, action) => {

  switch (action.type) {
    case Actions.SetView:
        return Array.isArray(action?.data) ? {...state, columns: action.data } : state
    case Actions.Hydrate:
      return action?.data ? action.data : state
  }

  return state
}

export default ViewReducer