import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from 'urql'
import './style.scss'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import ScreenHeader from '../../components/ScreenHeader'
import { GetDeals, GetUsers } from '../../graphql/queries'
import { Responses, UserRoles } from '../../util/consts'
import Input from '../../components/Input'
import { AssignDeal, CreateProperty, RemoveDeal } from '../../graphql/mutations'
import AddDealModal from '../../components/AddDealModal'
import FilterTable from '../../components/FilterTable'
import { formatDate } from '../../util/format'
import ImportModal from '../../components/ImportModal'
import EditDealModal from '../../components/EditDealModal'
import DealFilters from '../../components/DealFilters'
import ViewIcon from '../../components/icons/view'
import ImportIcon from '../../components/icons/import'
import PlusIcon from '../../components/icons/plus'
import DeleteIcon from '../../components/icons/delete'
import SelectInput from '../../components/SelectInput'
import { useAuth } from '../../providers/auth'
import Tasks from '../../components/Tasks'
import ConfirmModal from '../../components/ConfirmModal'
import DealsViewModal from '../../components/DealsViewModal'
import { useView } from '../../providers/view'
import AssignIcon from '../../components/icons/assign'
import UserPriorities from '../../components/UserPriorities'
import { useDealSubscription } from '../../providers/deal'

const AddPropertyForm = ({
    dealId,
}) => {
    const { t } = useTranslation()
    const [label, setLabel] = useState('')
    const [loading, setLoading] = useState(false)
    const [, addProperty] = useMutation(CreateProperty)

    const handleAdd = async () => {
        if (!label) return

        setLoading(true)
        try {
            const res = await addProperty({
                propertyId: label,
                dealId: dealId,
            })

            console.log(res)
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='deals--add-spot'>
            <h5>{ t('deals.add_spot_title') }</h5>
            <Input
                label={t('deals.spot_label')}
                value={label}
                onChange={(e) => setLabel(e.target.value)}
            />
            <Button
                label={t('deals.add_spot')}
                onClick={handleAdd}
                loading={loading}
            />
        </div>
    )
}

AddPropertyForm.propTypes = {
    dealId: PropTypes.number,
}

const initialSubResult = {
    addedResult: null,
    removedResult: null,
    updatedResult: null,
}

const DealsScreen = () => {

    const { t } = useTranslation()
    const { user } = useAuth()
    const [{ columns }] = useView()
    const tableRef = useRef()
    const tasksRef = useRef()
    const [showAddModal, setShowAddModal] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)
    const [showViewModal, setShowViewModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState(null)
    const [extraFilters, setExtraFilters] = useState()
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)
    const [users, setUsers] = useState([])
    const [, deleteDeal] = useMutation(RemoveDeal)
    const [, assignDeal] = useMutation(AssignDeal)
    const [subResults] = useDealSubscription()

    const [usersQuery] = useQuery({
        query: GetUsers,
        variables: {
            page: 0,
            limit: 100,
            orderBy: 'name',
        },
    })
    
    useEffect(() => {
        if (usersQuery?.fetching) return

        setUsers(usersQuery.data.getUsers || [])
    }, [usersQuery?.fetching, usersQuery?.data])

    const handleAddSuccess = () => {
        setShowAddModal(false)
    }

    const handleImportSuccess = () => {
        setShowImportModal(false)
        tableRef.current?.refresh()
        tasksRef.current?.refresh()
    }

    const handleEditSuccess = () => {
        setSelectedRow(null)
    }

    const handleDelete = async (id) => {
        try {
            const deleteRes = await deleteDeal({
                ids: selectedRows.map((row) => row.id),
            })
    
            if (
                !deleteRes?.data?.removeDeal
                || deleteRes.data.removeDeal !== Responses.Success
            ) return
    
            setSelectedRows([])
            setDeleteModal(false)
            tableRef.current?.refresh()
        } catch (err) {
            console.log(err)
        }
    }

    const handleAssign = async () => {
        try {
            const assignRes = await assignDeal({
                ids: selectedRows.map((row) => row.id),
                userId: selectedUser?.id ? parseInt(selectedUser.id, 10) : null,
            })
    
            if (
                !assignRes?.data?.assignDeal
                || assignRes.data.assignDeal !== Responses.Success
            ) return
    
            tableRef.current?.refresh()
        } catch (err) {
            console.log(err)
        }
    }

    const handleRowClick = (selection) => {
        if (selectedRows.length === 1 && selectedRows[0] === selection) {
            setSelectedRows([])
            return
        }
        if (Array.isArray(selection)) {
            setSelectedRows(selection)
        } else {
            setSelectedRows([selection])
        }
    }

    const renderHeaderActions = () => (
        <>
            {
                user?.role === UserRoles.Admin ?
                <>
                    <Button
                        label={t('deals.add_new')}
                        onClick={() => setShowAddModal(true)}
                        icon={<PlusIcon />}
                    />
                    <Button
                        label={t('deals.import')}
                        icon={<ImportIcon />}
                        onClick={() => setShowImportModal(true)}
                    />
                </>
                :
                <></>
            }
            {
                selectedRows?.length && user?.role === UserRoles.Admin ?
                <>
                    <Button
                        label={`${t('deals.delete')} ${selectedRows?.length ? `(${selectedRows.length})` : ''}`}
                        icon={<DeleteIcon />}
                        onClick={() => setDeleteModal(true)}
                    />
                    <div className='assign-deal'>
                        <SelectInput
                            options={users}
                            value={users.find(x => x.id === selectedUser?.id)}
                            getOptionLabel={(option) => t(option.name)}
                            getOptionValue={(option) => option.id}
                            onChange={(option) => setSelectedUser(option)}
                            isClearable={true}
                        />
                        <Button
                            className={'btn-assign'}
                            label={t('deals.assign')}
                            onClick={handleAssign}
                            icon={<AssignIcon />}
                        />
                    </div>
                </>
                :
                <></>
            }
            <Button
                className={'btn-view'}
                label={t('deals.change_view')}
                onClick={() => setShowViewModal(true)}
                icon={<ViewIcon />}
            />
        </>
    )

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'name': {
                if (row.properties?.some(x => x.property.status)) {
                    let statuses = row.properties?.filter(x => x.property?.status)?.map(x => x.property?.status)
                    statuses.sort((a, b) => a?.priority - b?.priority)

                    if (!statuses?.length) return row[field]

                    return <div className='property-status'>
                        <div style={{ backgroundColor: statuses[0].color }}></div>
                        <span>{ row[field] }</span>
                    </div>
                }
                return row[field]
            }
            case 'municipality': {
                const result = row.properties?.find(x => x.property.municipality)
                return result ? result.property.municipality : ''
            }
            case 'properties': {
                if (row.properties?.length) {
                    return (
                        <div className='deal-properties'>
                            {
                                row.properties.map((item, index) => (
                                    <div key={`deal-property-${index}-${item.property.id}`} className='deal-property'>
                                        <div className='deal-property-dot' style={{ backgroundColor: item?.property?.status?.color ?? '#000' }} />
                                        <div>{ `${item.property.propertyId || ''} ${item.property.address || ''}` }</div>
                                        <div>{ `${item.property.size || ''} ${item.property.sizeUnit || ''}` }</div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                return ''
            }
            case 'comment': {
                if (row.comments?.length) {
                    return (
                        <div className='deal-comments'>
                            {
                                row.comments.map((item, index) => (
                                    <div key={`deal-comments-${index}-${item.id}`} className='deal-comment'>
                                        <div>{ `${item.content}` }</div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                return ''
            }
            case 'creator':
                return row[field]?.name || ''
            case 'assignee':
                return row[field]?.name || ''
            case 'updatedAt':
                return formatDate(row[field], true)
            case 'status':
                return row[field] ? row[field].label : ''
            default:
                return row[field]
        }
    }

    const handleRowDoubleClick = (row) => {
        setSelectedRow(row)
    }

    const handleFilterChange = (filters) => {
        setExtraFilters(filters)
    }

    const handleViewChange = () => {
        setShowViewModal(false)
    }

    const handleDealClick = (priority) => {
        setSelectedRow(priority.deal)
    }

    return (
        <div className='deals'>
            <ScreenHeader
                title={ t('deals.title') }
                actions={renderHeaderActions}
            />

            <Tasks ref={tasksRef} />

            <DealFilters
                onFilterChange={handleFilterChange}
            />

            <UserPriorities onDealClick={handleDealClick} dealShown={!!selectedRow} />

            <FilterTable
                ref={tableRef}
                query={GetDeals}
                queryKey='getDeals'
                includeFields={columns}
                fieldConditions={fieldConditions}
                initialOrderBy='updatedAt'
                hideInputs={true}
                allowSelect={true}
                extraFilters={extraFilters}
                useExtraFilters={true}
                onRowDoubleClick={handleRowDoubleClick}
                onRowClick={handleRowClick}
                activeRows={selectedRows}
                subscriptions={{
                    add: subResults.addedResult,
                    update: subResults.updatedResult,
                    remove: subResults.removedResult,
                }}
            />

            <AddDealModal
                show={showAddModal}
                onSuccess={handleAddSuccess}
                close={() => setShowAddModal(false)}
            />

            <EditDealModal
                show={selectedRow !== null}
                onSuccess={handleEditSuccess}
                data={selectedRow}
                close={() => setSelectedRow(null)}
            />

            <ImportModal
                show={showImportModal}
                onSuccess={handleImportSuccess}
                close={() => setShowImportModal(false)}
            />

            <DealsViewModal
                show={showViewModal}
                onSuccess={handleViewChange}
                close={() => setShowViewModal(false)}
            />

            <ConfirmModal
                show={deleteModal}
                confirmLabel={`${t('deals.delete')} ${selectedRows?.length ? `${selectedRows.length} rida` : ''}`}
                close={() => setDeleteModal(false)}
                onConfirm={handleDelete}
                title={t('deals.confirm_delete')}
            />
        </div>
    )
}

export default DealsScreen