import { ReducerActions } from '../util/consts'

const initialState = {
  addedResult: null,
  removedResult: null,
  updatedResult: null,
}

const DealReducer = (state, action) => {

  switch (action.type) {
    case ReducerActions.Add:
        return {
          ...initialState,
          addedResult: action.data,
        }
    case ReducerActions.Update: {
      return {
        ...initialState,
        updatedResult: action.data,
      }
    }
    case ReducerActions.Delete: {
      return {
        ...initialState,
        removedResult: action.data,
      }
    }
    default:
      return state
  }
}

export default DealReducer