export const Municipalities = [
    {
        "ONIMI": "Alutaguse vald",
        "OKOOD": "0130",
        "MNIMI": "Ida-Viru maakond",
        "MKOOD": "0045",
        "TYYP": 1,
        "AREA": 1458607269.290905
    },
    {
        "ONIMI": "Anija vald",
        "OKOOD": "0141",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 532973670.3428485
    },
    {
        "ONIMI": "Antsla vald",
        "OKOOD": "0142",
        "MNIMI": "Võru maakond",
        "MKOOD": "0087",
        "TYYP": 1,
        "AREA": 410524751.6828504
    },
    {
        "ONIMI": "Elva vald",
        "OKOOD": "0171",
        "MNIMI": "Tartu maakond",
        "MKOOD": "0079",
        "TYYP": 1,
        "AREA": 732269381.7025465
    },
    {
        "ONIMI": "Haapsalu linn",
        "OKOOD": "0184",
        "MNIMI": "Lääne maakond",
        "MKOOD": "0056",
        "TYYP": 4,
        "AREA": 271822949.047045
    },
    {
        "ONIMI": "Haljala vald",
        "OKOOD": "0191",
        "MNIMI": "Lääne-Viru maakond",
        "MKOOD": "0060",
        "TYYP": 1,
        "AREA": 549093718.724472
    },
    {
        "ONIMI": "Harku vald",
        "OKOOD": "0198",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 159020106.34391662
    },
    {
        "ONIMI": "Hiiumaa vald",
        "OKOOD": "0205",
        "MNIMI": "Hiiu maakond",
        "MKOOD": "0039",
        "TYYP": 1,
        "AREA": 1032439885.268013
    },
    {
        "ONIMI": "Häädemeeste vald",
        "OKOOD": "0214",
        "MNIMI": "Pärnu maakond",
        "MKOOD": "0068",
        "TYYP": 1,
        "AREA": 494333521.7821298
    },
    {
        "ONIMI": "Järva vald",
        "OKOOD": "0255",
        "MNIMI": "Järva maakond",
        "MKOOD": "0052",
        "TYYP": 1,
        "AREA": 1222815396.3231993
    },
    {
        "ONIMI": "Jõelähtme vald",
        "OKOOD": "0245",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 211405560.68826926
    },
    {
        "ONIMI": "Jõgeva vald",
        "OKOOD": "0247",
        "MNIMI": "Jõgeva maakond",
        "MKOOD": "0050",
        "TYYP": 1,
        "AREA": 1039662265.0815506
    },
    {
        "ONIMI": "Jõhvi vald",
        "OKOOD": "0251",
        "MNIMI": "Ida-Viru maakond",
        "MKOOD": "0045",
        "TYYP": 1,
        "AREA": 123929416.55924992
    },
    {
        "ONIMI": "Kadrina vald",
        "OKOOD": "0272",
        "MNIMI": "Lääne-Viru maakond",
        "MKOOD": "0060",
        "TYYP": 1,
        "AREA": 353921939.1151498
    },
    {
        "ONIMI": "Kambja vald",
        "OKOOD": "0283",
        "MNIMI": "Tartu maakond",
        "MKOOD": "0079",
        "TYYP": 1,
        "AREA": 275121587.3240511
    },
    {
        "ONIMI": "Kanepi vald",
        "OKOOD": "0284",
        "MNIMI": "Põlva maakond",
        "MKOOD": "0064",
        "TYYP": 1,
        "AREA": 524677755.9593505
    },
    {
        "ONIMI": "Kastre vald",
        "OKOOD": "0291",
        "MNIMI": "Tartu maakond",
        "MKOOD": "0079",
        "TYYP": 1,
        "AREA": 492739481.85745263
    },
    {
        "ONIMI": "Kehtna vald",
        "OKOOD": "0293",
        "MNIMI": "Rapla maakond",
        "MKOOD": "0071",
        "TYYP": 1,
        "AREA": 511966058.2328502
    },
    {
        "ONIMI": "Keila linn",
        "OKOOD": "0296",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 4,
        "AREA": 11222680.48640026
    },
    {
        "ONIMI": "Kihnu vald",
        "OKOOD": "0303",
        "MNIMI": "Pärnu maakond",
        "MKOOD": "0068",
        "TYYP": 1,
        "AREA": 17325930.7698573
    },
    {
        "ONIMI": "Kiili vald",
        "OKOOD": "0304",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 100380335.21530017
    },
    {
        "ONIMI": "Kohila vald",
        "OKOOD": "0317",
        "MNIMI": "Rapla maakond",
        "MKOOD": "0071",
        "TYYP": 1,
        "AREA": 230108003.98734996
    },
    {
        "ONIMI": "Kohtla-Järve linn",
        "OKOOD": "0321",
        "MNIMI": "Ida-Viru maakond",
        "MKOOD": "0045",
        "TYYP": 4,
        "AREA": 39344135.43254939
    },
    {
        "ONIMI": "Kose vald",
        "OKOOD": "0338",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 532807397.15339935
    },
    {
        "ONIMI": "Kuusalu vald",
        "OKOOD": "0353",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 709495423.7859597
    },
    {
        "ONIMI": "Loksa linn",
        "OKOOD": "0424",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 4,
        "AREA": 3816803.653993916
    },
    {
        "ONIMI": "Luunja vald",
        "OKOOD": "0432",
        "MNIMI": "Tartu maakond",
        "MKOOD": "0079",
        "TYYP": 1,
        "AREA": 131797672.40299979
    },
    {
        "ONIMI": "Lääne-Harju vald",
        "OKOOD": "0431",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 645714237.7251278
    },
    {
        "ONIMI": "Lääne-Nigula vald",
        "OKOOD": "0441",
        "MNIMI": "Lääne maakond",
        "MKOOD": "0056",
        "TYYP": 1,
        "AREA": 1448774421.6428878
    },
    {
        "ONIMI": "Lääneranna vald",
        "OKOOD": "0430",
        "MNIMI": "Pärnu maakond",
        "MKOOD": "0068",
        "TYYP": 1,
        "AREA": 1362665676.3014834
    },
    {
        "ONIMI": "Lüganuse vald",
        "OKOOD": "0442",
        "MNIMI": "Ida-Viru maakond",
        "MKOOD": "0045",
        "TYYP": 1,
        "AREA": 598626888.627648
    },
    {
        "ONIMI": "Maardu linn",
        "OKOOD": "0446",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 4,
        "AREA": 23441394.13667627
    },
    {
        "ONIMI": "Muhu vald",
        "OKOOD": "0478",
        "MNIMI": "Saare maakond",
        "MKOOD": "0074",
        "TYYP": 1,
        "AREA": 207913689.34170672
    },
    {
        "ONIMI": "Mulgi vald",
        "OKOOD": "0480",
        "MNIMI": "Viljandi maakond",
        "MKOOD": "0084",
        "TYYP": 1,
        "AREA": 880726028.9060041
    },
    {
        "ONIMI": "Mustvee vald",
        "OKOOD": "0486",
        "MNIMI": "Jõgeva maakond",
        "MKOOD": "0050",
        "TYYP": 1,
        "AREA": 615642276.7914835
    },
    {
        "ONIMI": "Märjamaa vald",
        "OKOOD": "0503",
        "MNIMI": "Rapla maakond",
        "MKOOD": "0071",
        "TYYP": 1,
        "AREA": 1163524990.1475039
    },
    {
        "ONIMI": "Narva linn",
        "OKOOD": "0511",
        "MNIMI": "Ida-Viru maakond",
        "MKOOD": "0045",
        "TYYP": 4,
        "AREA": 68720729.65821385
    },
    {
        "ONIMI": "Narva-Jõesuu linn",
        "OKOOD": "0514",
        "MNIMI": "Ida-Viru maakond",
        "MKOOD": "0045",
        "TYYP": 4,
        "AREA": 404663513.9343998
    },
    {
        "ONIMI": "Nõo vald",
        "OKOOD": "0528",
        "MNIMI": "Tartu maakond",
        "MKOOD": "0079",
        "TYYP": 1,
        "AREA": 169110540.3610991
    },
    {
        "ONIMI": "Otepää vald",
        "OKOOD": "0557",
        "MNIMI": "Valga maakond",
        "MKOOD": "0081",
        "TYYP": 1,
        "AREA": 520214307.9375009
    },
    {
        "ONIMI": "Paide linn",
        "OKOOD": "0567",
        "MNIMI": "Järva maakond",
        "MKOOD": "0052",
        "TYYP": 4,
        "AREA": 442872235.181052
    },
    {
        "ONIMI": "Peipsiääre vald",
        "OKOOD": "0586",
        "MNIMI": "Tartu maakond",
        "MKOOD": "0079",
        "TYYP": 1,
        "AREA": 652162707.5712305
    },
    {
        "ONIMI": "Pärnu linn",
        "OKOOD": "0624",
        "MNIMI": "Pärnu maakond",
        "MKOOD": "0068",
        "TYYP": 4,
        "AREA": 857936187.7150091
    },
    {
        "ONIMI": "Põhja-Pärnumaa vald",
        "OKOOD": "0638",
        "MNIMI": "Pärnu maakond",
        "MKOOD": "0068",
        "TYYP": 1,
        "AREA": 1010533873.9801989
    },
    {
        "ONIMI": "Põhja-Sakala vald",
        "OKOOD": "0615",
        "MNIMI": "Viljandi maakond",
        "MKOOD": "0084",
        "TYYP": 1,
        "AREA": 1153007661.130451
    },
    {
        "ONIMI": "Põltsamaa vald",
        "OKOOD": "0618",
        "MNIMI": "Jõgeva maakond",
        "MKOOD": "0050",
        "TYYP": 1,
        "AREA": 889557234.4892479
    },
    {
        "ONIMI": "Põlva vald",
        "OKOOD": "0622",
        "MNIMI": "Põlva maakond",
        "MKOOD": "0064",
        "TYYP": 1,
        "AREA": 705892485.7110999
    },
    {
        "ONIMI": "Raasiku vald",
        "OKOOD": "0651",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 158946721.3112994
    },
    {
        "ONIMI": "Rae vald",
        "OKOOD": "0653",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 206777873.49955088
    },
    {
        "ONIMI": "Rakvere linn",
        "OKOOD": "0663",
        "MNIMI": "Lääne-Viru maakond",
        "MKOOD": "0060",
        "TYYP": 4,
        "AREA": 10746050.91125017
    },
    {
        "ONIMI": "Rakvere vald",
        "OKOOD": "0661",
        "MNIMI": "Lääne-Viru maakond",
        "MKOOD": "0060",
        "TYYP": 1,
        "AREA": 294662105.23740095
    },
    {
        "ONIMI": "Rapla vald",
        "OKOOD": "0668",
        "MNIMI": "Rapla maakond",
        "MKOOD": "0071",
        "TYYP": 1,
        "AREA": 859456868.2913511
    },
    {
        "ONIMI": "Ruhnu vald",
        "OKOOD": "0689",
        "MNIMI": "Saare maakond",
        "MKOOD": "0074",
        "TYYP": 1,
        "AREA": 11895746.058823694
    },
    {
        "ONIMI": "Räpina vald",
        "OKOOD": "0708",
        "MNIMI": "Põlva maakond",
        "MKOOD": "0064",
        "TYYP": 1,
        "AREA": 592774474.7983019
    },
    {
        "ONIMI": "Rõuge vald",
        "OKOOD": "0698",
        "MNIMI": "Võru maakond",
        "MKOOD": "0087",
        "TYYP": 1,
        "AREA": 933221653.9115934
    },
    {
        "ONIMI": "Saarde vald",
        "OKOOD": "0712",
        "MNIMI": "Pärnu maakond",
        "MKOOD": "0068",
        "TYYP": 1,
        "AREA": 1064798730.2978007
    },
    {
        "ONIMI": "Saaremaa vald",
        "OKOOD": "0714",
        "MNIMI": "Saare maakond",
        "MKOOD": "0074",
        "TYYP": 1,
        "AREA": 2717837620.682283
    },
    {
        "ONIMI": "Saku vald",
        "OKOOD": "0718",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 170448826.2093485
    },
    {
        "ONIMI": "Saue vald",
        "OKOOD": "0726",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 627606834.9448507
    },
    {
        "ONIMI": "Setomaa vald",
        "OKOOD": "0732",
        "MNIMI": "Võru maakond",
        "MKOOD": "0087",
        "TYYP": 1,
        "AREA": 463095123.8437826
    },
    {
        "ONIMI": "Sillamäe linn",
        "OKOOD": "0735",
        "MNIMI": "Ida-Viru maakond",
        "MKOOD": "0045",
        "TYYP": 4,
        "AREA": 11907094.852075677
    },
    {
        "ONIMI": "Tallinn",
        "OKOOD": "0784",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 4,
        "AREA": 159370922.06541863
    },
    {
        "ONIMI": "Tapa vald",
        "OKOOD": "0792",
        "MNIMI": "Lääne-Viru maakond",
        "MKOOD": "0060",
        "TYYP": 1,
        "AREA": 479664371.3719987
    },
    {
        "ONIMI": "Tartu linn",
        "OKOOD": "0793",
        "MNIMI": "Tartu maakond",
        "MKOOD": "0079",
        "TYYP": 4,
        "AREA": 153991953.1448996
    },
    {
        "ONIMI": "Tartu vald",
        "OKOOD": "0796",
        "MNIMI": "Tartu maakond",
        "MKOOD": "0079",
        "TYYP": 1,
        "AREA": 742118101.8830702
    },
    {
        "ONIMI": "Toila vald",
        "OKOOD": "0803",
        "MNIMI": "Ida-Viru maakond",
        "MKOOD": "0045",
        "TYYP": 1,
        "AREA": 265780540.24150762
    },
    {
        "ONIMI": "Tori vald",
        "OKOOD": "0809",
        "MNIMI": "Pärnu maakond",
        "MKOOD": "0068",
        "TYYP": 1,
        "AREA": 611127042.3780508
    },
    {
        "ONIMI": "Tõrva vald",
        "OKOOD": "0824",
        "MNIMI": "Valga maakond",
        "MKOOD": "0081",
        "TYYP": 1,
        "AREA": 647220271.0006464
    },
    {
        "ONIMI": "Türi vald",
        "OKOOD": "0834",
        "MNIMI": "Järva maakond",
        "MKOOD": "0052",
        "TYYP": 1,
        "AREA": 1008456538.1334019
    },
    {
        "ONIMI": "Valga vald",
        "OKOOD": "0855",
        "MNIMI": "Valga maakond",
        "MKOOD": "0081",
        "TYYP": 1,
        "AREA": 749659565.7141492
    },
    {
        "ONIMI": "Viimsi vald",
        "OKOOD": "0890",
        "MNIMI": "Harju maakond",
        "MKOOD": "0037",
        "TYYP": 1,
        "AREA": 73260649.37036192
    },
    {
        "ONIMI": "Viljandi linn",
        "OKOOD": "0897",
        "MNIMI": "Viljandi maakond",
        "MKOOD": "0084",
        "TYYP": 4,
        "AREA": 14665935.839299783
    },
    {
        "ONIMI": "Viljandi vald",
        "OKOOD": "0899",
        "MNIMI": "Viljandi maakond",
        "MKOOD": "0084",
        "TYYP": 1,
        "AREA": 1371638881.121325
    },
    {
        "ONIMI": "Vinni vald",
        "OKOOD": "0901",
        "MNIMI": "Lääne-Viru maakond",
        "MKOOD": "0060",
        "TYYP": 1,
        "AREA": 1012935255.6394514
    },
    {
        "ONIMI": "Viru-Nigula vald",
        "OKOOD": "0903",
        "MNIMI": "Lääne-Viru maakond",
        "MKOOD": "0060",
        "TYYP": 1,
        "AREA": 312235757.13725954
    },
    {
        "ONIMI": "Vormsi vald",
        "OKOOD": "0907",
        "MNIMI": "Lääne maakond",
        "MKOOD": "0056",
        "TYYP": 1,
        "AREA": 94981222.47607678
    },
    {
        "ONIMI": "Väike-Maarja vald",
        "OKOOD": "0928",
        "MNIMI": "Lääne-Viru maakond",
        "MKOOD": "0060",
        "TYYP": 1,
        "AREA": 682460727.2010026
    },
    {
        "ONIMI": "Võru linn",
        "OKOOD": "0919",
        "MNIMI": "Võru maakond",
        "MKOOD": "0087",
        "TYYP": 4,
        "AREA": 14009922.849299923
    },
    {
        "ONIMI": "Võru vald",
        "OKOOD": "0917",
        "MNIMI": "Võru maakond",
        "MKOOD": "0087",
        "TYYP": 1,
        "AREA": 952288359.0863996
    }
]