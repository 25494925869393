import { Actions, ReducerActions } from '../util/consts'

const PriorityReducer = (state, action) => {

  switch (action.type) {
    case ReducerActions.Load:
        return Array.isArray(action?.data) ? [...action.data] : state
    case ReducerActions.Add:
        return state.some(i => i.id === action.data.id) ? state : [action.data, ...state]
    case ReducerActions.Update: {
      const index = state.findIndex(i => i.id === action.data.id)

      if (index === -1) {
        return state
      }

      const newState = [...state]
      newState[index] = {
        ...newState[index],
        ...action.data,
      }

      return newState
    }
    case ReducerActions.Delete: {
      console.log('info sent to reducer', action)
      if (action.data && Array.isArray(action.data)) {
        console.log('removign from array', action.data)
        console.log(state)
        console.log('is item in array', state.some(i => action.data.includes(i.id)))
        return state.filter(i => !action.data.includes(i.id))
      }
      return state.filter(i => i.id !== action.data)
    }
    case Actions.Hydrate:
      return action?.data ? action.data : state
    default:
      return state
  }
}

export default PriorityReducer