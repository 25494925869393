import React from 'react'

const FileInput = ({
    onChange,
    label,
}) => {

    return (
        <div className='file-input'>
            <label>
                <span>{ label }</span>
                <input type='file' onChange={onChange} accept='.xls,.xlsx' />
            </label>
        </div>
    )
}

export default FileInput