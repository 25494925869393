import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FileUpload from './FileUpload'
import Modal from './Modal'
import { ImportProperties } from '../graphql/mutations'
import { useMutation } from 'urql'
import { Responses } from '../util/consts'

const ImportModal = ({
    show,
    close,
    onSuccess,
}) => {

    const { t } = useTranslation()
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)
    const [, importProperties] = useMutation(ImportProperties)

    const handleImport = async (file) => {
        setLoading(true)
    
        try {
            const res = await importProperties({
                file,
            })

            if (res?.data?.importProperties?.code === Responses.Success) {
                if (onSuccess) onSuccess()
                return
            }

            setError(t('deals.import_error'))
        } catch (err) {
            setError(t('deals.import_error'))
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = () => {
        if (close) close()
        setError(null)
    }

    return (
        <Modal
            className={'modal-properties-import'}
            show={show}
            close={handleClose}
            title={ t('deals.import') }
        >
            <FileUpload
                handleUpload={handleImport}
                loading={loading}
            />

            {
                error ?
                <div className='error-message'>
                    <span>{ error }</span>
                </div>
                :
                <></>
            }
        </Modal>
    )
}

export default ImportModal