import { authExchange } from '@urql/exchange-auth'
import { cacheExchange, createClient, makeOperation, mapExchange, fetchExchange, subscriptionExchange } from 'urql'

const getToken = () => {
  const token = localStorage.getItem('token')

  return {
    token,
  }
}
class Urql {
    
    constructor () {
        this.client = null
    }

    init = (onError) => {
        this.client = createClient({
            url: process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_GRAPHQL : 'http://localhost:3050/graphql',
            exchanges: [
                cacheExchange,
                authExchange(async (utils) => {
                  const { token } = getToken()

                  return {
                    addAuthToOperation(operation) {
                      if (!token) return operation

                      return utils.appendHeaders(operation, {
                        Authorization: `Bearer ${token}`,
                      })
                    },
                  }
                }),
                subscriptionExchange({
                  forwardSubscription(operation) {
                    const url = new URL(process.env.NODE_ENV !== 'development' ? process.env.REACT_APP_GRAPHQL : 'http://localhost:3050/graphql')
                    url.searchParams.append('query', operation.query)
                    url.searchParams.append('authorization', localStorage.getItem('token'))
                    if (operation.variables) {
                      url.searchParams.append(
                        'variables',
                        JSON.stringify(operation.variables)
                      )
                    }
                    return {
                      subscribe(sink) {
                        const eventsource = new EventSource(url.toString(), {
                          withCredentials: true // This is required for cookies
                        })
                        eventsource.onmessage = (event) => {
                          const data = JSON.parse(event.data)
                          sink.next(data)
                          if (eventsource.readyState === 2) {
                            sink.complete()
                          }
                        }
                        eventsource.onerror = (error) => {
                          console.log('error', error)
                          sink.error(error)
                        }
                        return {
                          unsubscribe: () => eventsource.close()
                        }
                      }
                    }
                  }
              }),
                fetchExchange,
                mapExchange({
                    onError,
                }),
            ],
        })

        return this.client
    }
}

const urql = new Urql()

export default urql