import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../providers/auth'
import Button from './Button'
import PlusIcon from './icons/plus'
import BoxNotification from './BoxNotification'
import { useMutation } from 'urql'
import { AddComment, EditComment } from '../graphql/mutations'
import Textarea from './Textarea'
import { formatDate } from '../util/format'
import { UserRoles } from '../util/consts'

const initialComment = {
    content: '',
}

const CommentInput = ({
    data,
    onChange,
    onRemove,
    onEdit,
}) => {
    const { t } = useTranslation()
    const [editable, setEditable] = useState(false)
    const [item, setItem] = useState(data || initialComment)
    const [prevValue, setPrevValue] = useState(null)
    const textareaRef = useRef(null)

    useEffect(() => {
        if (!editable) return

        textareaRef?.current?.focus()
        textareaRef?.current?.setSelectionRange(0, textareaRef?.current?.value.length)
    }, [editable])

    const setField = (field, value) => {
        const newItem = {
            ...item,
            [field]: value,
            updatedAt: new Date(),
        }
        setItem(newItem)
        if (onChange) onChange(newItem)
    }

    if (!item) return <></>

    return (
        <div className={'comment'}>
            <div className='comment-view'>
                <div className='comment-view--content'>
                    {
                        editable ?
                            <Textarea
                                ref={textareaRef}
                                label={t('deals.comment')}
                                onChange={e => setField('content', e.target.value)}
                                value={item.content}
                            />
                            :
                            item.content
                    }
                </div>
                <div className='comment-view--meta'>
                    <div className='comment-view--meta--date'>{formatDate(item.updatedAt, true)}</div>
                    <div className='comment-view--meta--author'>{item.creator?.name}</div>
                </div>
                <div className='comment-view--actions'>
                    {
                        onRemove && !editable ?
                            <Button
                                className={'btn-small btn-remove'}
                                label={t('deals.remove_comment')}
                                onClick={onRemove}
                            />
                            :
                            <></>
                    }
                    {
                        onEdit && editable ?
                            <Button
                                className={'btn-small btn-remove'}
                                label={t('deals.cancel')}
                                onClick={() => {
                                    setItem(prevValue)
                                    setEditable(false)
                                }}
                            />
                            :
                            <></>
                    }
                    {
                        onEdit ?
                            <Button
                                className={'btn-small btn-edit'}
                                label={editable ? t('deals.save_comment') : t('deals.edit_comment')}
                                onClick={() => {
                                    if (!editable) {
                                        setPrevValue(item)
                                        setEditable(true)
                                        return
                                    }
                                    onEdit(item)
                                    setEditable(false)
                                }}
                            />
                            :
                            <></>
                    }
                </div>
            </div>
        </div>
    )
}

CommentInput.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
}

const CommentForm = ({
    initialData,
    onChange,
    error,
}) => {
    const { t } = useTranslation()
    const { user } = useAuth()
    const [items, setItems] = useState(initialData || [])
    const [newComment, setNewComment] = useState(initialComment)
    const [, addComment] = useMutation(AddComment)
    const [, updateComment] = useMutation(EditComment)

    useEffect(() => {
        if (items && items.length > 0) return

        setItems(initialData)
    }, [initialData])

    const handleAdd = () => {
        if (!newComment?.content?.length) return

        const newItems = [...items, {
            ...newComment,
            updatedAt: new Date(),
            createdAt: new Date(),
            creator: {
                id: user?.id,
                name: user?.name,
            },
        }]
        setItems(newItems)

        if (onChange) onChange(newItems)

        setNewComment(initialComment)
    }

    const updateItems = (item, index) => {
        const newItems = [...items]
        newItems[index] = {
            ...item,
            price: item?.price?.length ? parseFloat(item.price) : undefined,
        }
        setItems(newItems)

        if (onChange) onChange(newItems)
    }

    const handleRemove = (index) => {
        if (index < 0) return

        const newItems = items.filter((_, i) => i !== index)
        setItems(newItems)

        if (onChange) onChange(newItems)
    }

    const handleEdit = async (item) => {
        await updateComment({
            id: parseInt(item.id),
            message: item.content,
        })
    }

    return (
        <div className={'comments'}>
            <h4>{t('deals.comments')}</h4>
            {
                items?.map((item, index) =>
                    <CommentInput
                        key={`comment-${index}`}
                        data={item}
                        onChange={(data) => updateItems(data, index)}
                        onRemove={user?.role === UserRoles.Admin || item.creatorId === user.id ? () => handleRemove(index) : undefined}
                        onEdit={user?.id === item.creatorId ? handleEdit : undefined}
                    />
                )
            }
            {
                error ?
                    <BoxNotification
                        message={error}
                        type={'error'}
                    />
                    :
                    <></>
            }

            <Textarea
                label={t('deals.add_comment')}
                onChange={e => setNewComment({ ...newComment, content: e.target.value })}
                value={newComment.content}
            />
            <Button
                label={t('deals.add_comment')}
                onClick={handleAdd}
                icon={<PlusIcon />}
            />
        </div>
    )
}

CommentForm.propTypes = {
    onChange: PropTypes.func,
}

export default CommentForm