import React from 'react'
import { useQuery } from 'urql'
import { GetPropertyExists } from '../graphql/queries'
import Alert from './Alert'

const ExistingProperty = ({
    propertyId,
    dealId,
}) => {
    const [propertyQuery] = useQuery({
        query: GetPropertyExists,
        pause: !propertyId,
        variables: {
            propertyId,
            dealId,
        },
        requestPolicy: 'network-only',
    })

    if (!propertyId || !propertyQuery?.data?.getPropertyExists) return <></>

    return (
        <div className='existing-property'>
            <Alert />
        </div>
    )
}

export default ExistingProperty