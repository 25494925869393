import React from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import Statuses from '../../components/Statuses'

const SettingsScreen = () => {

    const { t } = useTranslation()

    return (
        <div className='settings'>
            <h3>{ t('settings.title') }</h3>
            <Statuses

            />
        </div>
    )
}

export default SettingsScreen