import React from 'react'
import { useQuery } from 'urql'
import { GetPersonExists } from '../graphql/queries'
import Alert from './Alert'

const ExistingContact = ({
    email,
    pid,
    dealId,
}) => {
    const [contactQuery] = useQuery({
        query: GetPersonExists,
        pause: (!email && !pid) || !dealId,
        variables: {
            email,
            pid,
            dealId,
        },
        requestPolicy: 'network-only',
    })

    if ((!email && !pid) || !contactQuery?.data?.getPersonExists) return <></>

    return (
        <div className='existing-contact'>
            <Alert />
        </div>
    )
}

export default ExistingContact