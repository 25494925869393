import React from 'react'
import { Dots } from 'react-activity'

const ActivityIndicator = ({
    message,
    color,
}) => (
    <div className='activity-indicator'>
        <Dots color={color || '#49a175'} speed={0.5} />
        {
            message ?
            <div
                className='activity-indicator--message'
                style={{
                    ...(color && {
                        color,
                    })
                }}
            >
                { message }
            </div>
            :
            <></>
        }
    </div>
)

export default ActivityIndicator