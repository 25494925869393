import React, { useEffect, useState } from 'react'
import { useQuery } from 'urql'
import { GetPersonProperties } from '../graphql/queries'
import ActivityIndicator from './ActivityIndicator'
import Button from './Button'

const PersonProperties = ({
    onResult,
    onStartLoading,
    persons,
}) => {
    const [result, setResult] = useState(null)
    const [personList, setPersonList] = useState(null)

    const [query] = useQuery({
        query: GetPersonProperties,
        pause: !personList,
        variables: {
            pid: personList,
        },
        requestPolicy: 'network-only',
    })

    useEffect(() => {
        if (query.fetching) {
            if (onStartLoading) onStartLoading()
            return
        }

        setResult(query.data?.getPersonProperties)
        setPersonList(null)

        if (onResult) onResult(query.data?.getPersonProperties)

        setTimeout(() => {}, [
            setResult(null)
        ], 3000)
    }, [query])

    const handleClick = () =>{
        setPersonList(persons.map(i => i.pid))
    }

    return (
        <Button
            label={result ? 'Kinnisturaamatu vaste leitud' : 'Otsi Kinnisturaamatust'}
            loading={query.fetching}
            disabled={result}
            onClick={handleClick}
            className={'btn--property-finder'}
        />
    )
}

export default PersonProperties