import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePriorities } from '../providers/priorities'
import { formatDate } from '../util/format'
import { useQuery } from 'urql'
import { GetDeal } from '../graphql/queries'

const UserPriorities = ({
    onDealClick,
    dealShown,
}) => {

    const { t } = useTranslation()
    const [priorities] = usePriorities()
    const [filteredPriorities, setFilteredPriorities] = useState([])
    const [item, setItem] = useState(null)
    const [dealQuery] = useQuery({
        requestPolicy: 'network-only',
        query: GetDeal,
        pause: !item?.deal,
        variables: {
            id: item?.deal?.id,
        },
    })

    useEffect(() => {
        if (!priorities?.length) {
            setFilteredPriorities([])
            return
        }

        setFilteredPriorities(priorities.filter(i => i.assigneeId))
    }, [priorities])

    useEffect(() => {
        setItem(null)
    }, [dealShown])

    useEffect(() => {
        if (dealQuery.fetching || !dealQuery.data?.getDeal) return

        if (onDealClick) onDealClick({
            ...item,
            deal: dealQuery.data.getDeal,
        })
    }, [dealQuery])

    const handleClick = (priority) => {
        console.log('handleClick', priority)
        setItem({...priority})
    }

    if (filteredPriorities.length < 1) return <></>

    return (
        <div className='user-priorities'>
            {
                filteredPriorities.map(priority => (
                    <div key={priority.id} className='user-priority' onClick={() => handleClick(priority)}>
                        <div className='user-priority-content'>
                            <h3>{priority.deal?.name}</h3>
                        </div>
                        <div className='user-priority-meta'>
                            <p>{priority.creator?.name}</p>
                            <p>{ formatDate(priority.createdAt, true) }</p>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default UserPriorities