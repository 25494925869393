import React from 'react'
import PropTypes from 'prop-types'

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props)
      this.state = { hasError: false }
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      console.log('Derived error:', error)
      return { hasError: true }
    }
  
    componentDidCatch(error, errorInfo) {
      console.log(error, errorInfo)
      console.log(this.hasError)
    }
  
    render() {
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <h1>Something went wrong.</h1>
      }
  
      return this.props.children 
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.element,
}

export default ErrorBoundary