import { useEffect, useState } from 'react'

const useNetwork = () => {

    const [isOnline, setIsOnline] = useState(navigator.onLine)

    const update = () => {
        setIsOnline(navigator.onLine)
    }

    useEffect(() => {
        window.addEventListener("offline", update)
        window.addEventListener("online", update)

        return () => {
           window.removeEventListener("offline", update)
           window.removeEventListener("online", update)
        }
    }, [])

    return isOnline
}

export default useNetwork