import React from 'react'
import ActivityIndicator from './ActivityIndicator'

const LoadingComponent = ({
    color,
}) => (
    <div className='loading-view'>
        <ActivityIndicator message={'Laen andmeid'} color={color} />
    </div>
)

export default LoadingComponent