import React from 'react'
import PropTypes from 'prop-types'

const AssignIcon = ({ fill }) => (
    <svg version="1.1" baseProfile="tiny" fill={fill || '#49a175'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" xmlSpace="preserve">
        <circle cx="17.99" cy="10.36" r="6.81" />
        <path d="M12,26.65a2.8,2.8,0,0,1,4.85-1.8L20.71,29l6.84-7.63A16.81,16.81,0,0,0,18,18.55,16.13,16.13,0,0,0,5.5,24a1,1,0,0,0-.2.61V30a2,2,0,0,0,1.94,2h8.57l-3.07-3.3A2.81,2.81,0,0,1,12,26.65Z" />
        <path d="M28.76,32a2,2,0,0,0,1.94-2V26.24L25.57,32Z" />
        <path d="M33.77,18.62a1,1,0,0,0-1.42.08l-11.62,13-5.2-5.59A1,1,0,0,0,14.12,26a1,1,0,0,0,0,1.42l6.68,7.2L33.84,20A1,1,0,0,0,33.77,18.62Z" />
    </svg>

)

AssignIcon.propTypes = {
    fill: PropTypes.string,
}

export default AssignIcon
