import React, { useEffect, useState } from 'react'
import './style.scss'

import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import Button from '../../components/Button'
import Input from '../../components/Input'
import BoxNotification from '../../components/BoxNotification'
import { useAuth } from '../../providers/auth'
import { Login, ResetPassword } from '../../graphql/mutations'
import { Responses } from '../../util/consts'

const initialCredentials = {
    email: '',
    password: '',
}

const LoginScreen = () => {

    const { t } = useTranslation()
    const { signIn } = useAuth()
    const [error, setError] = useState()
    const [forgotError, setForgotError] = useState()
    const [forgot, setForgot] = useState()
    const [forgotSent, setForgotSent] = useState()
    const [forgotLoading, setForgotLoading] = useState(false)
    const [credentials, setCredentials] = useState(initialCredentials)
    const [, resetPass] = useMutation(ResetPassword)
    const [, loginWithEmail] = useMutation(Login)

    const reset = () => setError(null)

    useEffect(() => {
        console.log('Login')
        signIn()

        return () => reset()
    }, [])

    const login = async () => {
        try {
            const res = await loginWithEmail({
                email: credentials.email,
                password: credentials.password,
            })

            if (res?.data?.login?.token) {
                console.log(res)
                signIn(res.data.login.token)
                return
            }

            setError(t('login.error'))
        } catch (err) {
            setError(t('login.error'))
            console.log('login: ', err)
        }
    }

    const resetPassword = async () => {
        setForgotLoading(true)
        try {
            const res = await resetPass({
                email: credentials.email,
            })

            if (res?.resetPassword === Responses.Failed) {
                setForgotError(true)
                return
            }

            setForgotSent(true)
        } catch (err) {
            setForgotError(true)
            console.log('resetPassword: ', err)
        } finally {
            setForgotLoading(false)
        }
    }

    const handleCancelForgot = () => {
        setForgotError(null)
        setForgotSent(false)
        setForgot(false)
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter') login()
    }

    const renderForgot = () => (
        <div className='login-method login-method--user-forgot'>
            <h4>{ t('login.forgot_password') }</h4>
            {
                forgotSent ?
                <div className='login-method--user-forgot-sent'>
                    <p>{ t('login.forgot_sent') }</p>
                    <Button
                        className={'btn-cancel'}
                        label={t('login.back')}
                        onClick={handleCancelForgot}
                    />
                </div>
                :
                <div className='login-method--input'>
                    <Input
                        label={ t('login.email') }
                        onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
                        value={credentials.email}
                        type={'email'}
                    />
                    {
                        forgotError ?
                        <BoxNotification type={'error'} message={t('login.forgot_error')} />
                        :
                        <></>
                    }
                    <div className='login-actions'>
                        <Button
                            className={'btn-cancel'}
                            label={t('login.cancel')}
                            onClick={handleCancelForgot}
                        />
                        <Button
                            loading={forgotLoading}
                            label={t('login.reset_password')}
                            onClick={resetPassword}
                        />
                    </div>
                </div>
            }
        </div>
    )

    return (
        <div className='login'>
            <div className='login-background'>
                <div className='login-background--image'></div>
                <div className='login-background--filter'></div>
            </div>
        {
            forgot ?
            renderForgot()
            :
            <div className='login-method login-method--user'>
                <div className='login-brand'>
                    <span>{ 'lepalaane' }</span>
                </div>
                <div className='login-method--input'>
                    <Input
                        label={ t('login.email') }
                        onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
                        value={credentials.email}
                        type={'email'}
                    />
                    <Input
                        label={ t('login.password') }
                        type={'password'}
                        value={credentials.password}
                        onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
                        onKeyUp={handleKeyUp}
                    />
                    {
                    error ?
                        <BoxNotification type={'error'} message={t('login.error')} />
                        :
                        <></>
                    }
                    <div className='login-actions'>
                        <Button
                            label={t('login.enter')}
                            onClick={login}
                        />
                    </div>
                    <div className='other-actions'>
                        <a onClick={() => setForgot(true)}>{ t('login.forgot_password') }</a>
                    </div>
                </div>
            </div>
        }
        </div>
    )
}

export default LoginScreen