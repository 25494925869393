import React from 'react'
import ExclamationIcon from './icons/exclamation'

const Alert = () => {
    return (
        <div className='alert'>
            <ExclamationIcon />
        </div>
    )
}

export default Alert