import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'

const DateInput = ({
    label,
    value,
    onChange,
    placeholder,
    onBlur,
    onFocus,
    error,
    selectsRange,
    startDate,
    endDate,
    isClearable,
}) => {

  const { t } = useTranslation()
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
    if (onFocus) onFocus()
  }
  const handleBlur = () => {
    setIsFocused(false)
    if (onBlur) onBlur()
  }

  const handleChange = (e) => {
    if (onChange) onChange(e)
    handleBlur()
  }

  return (
    <div className={`input-wrapper input-wrapper--date${error ? ' input-wrapper--error' : ''}`}>
      <label>
      { label && <span>{ label }</span> }
      </label>

      <div className={`input-wrapper--control${isFocused ? ' input-wrapper--control-focused' : ''}`}>
        <div className='input-wrapper--input'>
          {
            selectsRange ?
            <DatePicker
                onChange={handleChange}
                startDate={startDate}
                endDate={endDate}
                // onBlur={handleBlur}
                dateFormat={`dd.MM.yyyy`}
                // onFocus={handleFocus}
                // onClickOutside={handleBlur}
                placeholderText={placeholder ? placeholder : t('Vali kuupäev')}
                selectsRange
                isClearable={isClearable}
            />
            :
            <DatePicker
                selected={value}
                onChange={handleChange}
                onBlur={handleBlur}
                dateFormat={`dd.MM.yyyy`}
                onFocus={handleFocus}
                onClickOutside={handleBlur}
                placeholderText={placeholder ? placeholder : t('Vali kuupäev')}
            />
          }
        </div>
      </div>

      { error &&
        <div className="input-error">{ error }</div>
      }
    </div>
  )
}

export default DateInput