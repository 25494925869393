import React, { useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'urql'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { ChangePassword } from '../../graphql/mutations'

const initialState = {
    password: '',
    passwordAgain: '',
}

const ProfileScreen = () => {

    const { t } = useTranslation()
    const [inputs, setInputs] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [, changePassword] = useMutation(ChangePassword)

    const handleInputChange = (field, value) => {
        console.log(value)
        setInputs({
            ...inputs,
            [field]: value,
        })
    }

    const handleSave = async () => {
        setLoading(true)
        if (inputs.password.length > 1 && inputs.passwordAgain.length > 1 && inputs.passwordAgain === inputs.password) {
            const { data } = await changePassword({
                password: inputs.password,
            })

            console.log('Saved password:', data)
        }
        setLoading(false)
    }

    return (
        <div className='profile'>
            <h3>{ t('profile.title') }</h3>
            <div className='profile-content'>
                <div className='profile-content--password'>
                    <h4>{ t('profile.change_password') }</h4>
                    <Input
                        label={t('profile.password')}
                        value={inputs.password}
                        onChange={(e) => handleInputChange('password', e.target.value)}
                        type='password'
                    />
                    <Input
                        label={t('profile.password_again')}
                        value={inputs.passwordAgain}
                        onChange={(e) => handleInputChange('passwordAgain', e.target.value)}
                        type='password'
                    />
                </div>
                <Button
                    label={t('profile.save')}
                    onClick={handleSave}
                    loading={loading}
                />
            </div>
        </div>
    )
}

export default ProfileScreen