import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from './Modal'
import Checkbox from './Checkbox'
import { useView } from '../providers/view'
import { Actions, ViewColumns } from '../util/consts'

const DealsViewModal = ({
    show,
    close,
}) => {

    const { t } = useTranslation()
    const [{ columns }, dispatch] = useView() 
    const [selected, setSelected] = useState([])

    useEffect(() => {
        if (!columns) return
        setSelected(columns)
    }, [])

    const handleClose = () => {
        if (close) close()
    }

    const handleSelect = (event, column) => {
        if (event.target.checked) {
            const updated = [...selected, column].sort((a, b) => a.index - b.index)
            setSelected(updated)

            dispatch({
                type: Actions.SetView,
                data: updated,
            })
            return
        }

        const updated = selected.filter((item) => item.value !== column.value).sort((a, b) => a.index - b.index)
        setSelected(updated)

        dispatch({
            type: Actions.SetView,
            data: updated,
        })
    }

    return (
        <Modal
            className={'modal-view'}
            show={show}
            close={handleClose}
            title={ t('deals.change_view') }
        >
            {
                ViewColumns.map((column) =>
                    <div className='view-column' key={column.value}>
                        <Checkbox
                            onChange={(e) => handleSelect(e, column)}
                            label={column.label}
                            value={selected.some(i => i.value === column.value)}
                        />
                    </div>
                )
            }
        </Modal>
    )
}

export default DealsViewModal