import React, { useEffect } from 'react'
import {
  Routes,
  Route,
  useLocation,
} from 'react-router-dom'
import { useMutation } from 'urql'
import AppHeader from './components/AppHeader'
import { ValidateToken } from './graphql/mutations'
import { useAuth } from './providers/auth'
import LoginScreen from './screens/Login'
import ProfileScreen from './screens/Profile'
import UsersScreen from './screens/Users'
import DealsScreen from './screens/Deals'
import SettingsScreen from './screens/Settings'
import StatisticsScreen from './screens/Statistics'
import BotScreen from './screens/Bot'

const Routing = () => {

  const { user, loaded } = useAuth()
  const { pathname } = useLocation()
  const [, validateUserToken] = useMutation(ValidateToken)

  useEffect(() => {
    const token = localStorage.getItem('token')
    validateUserToken({ token })
  }, [])
  

  if (!loaded) return <></>

  return (
      <div className={`wrapper`}>
        {
          user ?
          (
              <div>
                  <AppHeader />
                  <div className={`app-content${pathname && pathname.length > 1 ? ` ${pathname.split('/')[1]}` : ''}`}>
                      <Routes>
                        <Route path='/' element={<DealsScreen />} />
                        <Route path='/users' element={<UsersScreen />} />
                        <Route path='/profile' element={<ProfileScreen />} />
                        <Route path='/deals' element={<DealsScreen />} />
                        <Route path='/settings' element={<SettingsScreen />} />
                        <Route path='/stats' element={<StatisticsScreen />} />
                        <Route path='/bot' element={<BotScreen />} />
                      </Routes>
                  </div>
                  {/* <AppFooter /> */}
              </div>
          )
          :
          (
              <Routes>
                <Route
                    path='*'
                    element={<LoginScreen />}
                />
              </Routes>
          )
        }
      </div>
  )
}

export default Routing