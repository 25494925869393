import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'urql'
import { CreateStatus, UpdateStatus } from '../graphql/mutations'
import { GetStatuses } from '../graphql/queries'
import { Priority, Responses, StatusType } from '../util/consts'
import Button from './Button'
// import DeleteIcon from './icons/delete'
import Input from './Input'
import SelectInput from './SelectInput'

const priorities = Object.entries(Priority).map(([key, value]) => ({
    value,
    label: key,
}))

const types = Object.entries(StatusType).map(([key, value]) => ({
    value,
    label: key,
}))

const initialStatus = {
    label: '',
    color: '',
    priority: '',
    type: '',
    visible: false,
}

const Statuses = () => {

    const { t } = useTranslation()
    const [item, setItem] = useState(initialStatus)
    const [loading, setLoading] = useState(false)
    const [, createStatus] = useMutation(CreateStatus)
    const [, updateStatus] = useMutation(UpdateStatus)
    // const [, removeStatus] = useMutation(RemoveStatus)
    const [statuses, setStatuses] = useState([])
    const [statusQuery, refetch] = useQuery({
        query: GetStatuses,
        requestPolicy: 'network-only',
    })

    useEffect(() => {
        if (statusQuery?.fetching) return

        setStatuses(statusQuery?.data?.getStatuses?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)) || [])
    }, [statusQuery?.fetching, statusQuery?.data])

    const handleAdd = async () => {
        setLoading(true)

        try {
            const res = await createStatus({
                label: item.label,
                color: item.color,
                priority: parseInt(item.priority, 10),
                type: item.type,
                visible: item.visible,
            })
            if (res?.data?.createStatus === Responses.Success) {
                setItem(initialStatus)
                refetch()
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    // const handleRemove = async (statusId) => {
    //     try {
    //         const res = await removeStatus({
    //             id: parseInt(statusId, 10),
    //         })
    //         if (res?.data?.removeStatus === Responses.Success) {
    //             refetch()
    //         }
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    const setField = (field, value) => {
        setItem({
            ...item,
            [field]: value,
        })
    }

    const handleUpdate = async (index, field, value, type) => {
        const typeStatuses = statuses.filter(i => i.type === type.value)
        const otherStatuses = statuses.filter(i => i.type !== type.value)
        typeStatuses[index][field] = value

        setStatuses([...typeStatuses,...otherStatuses])

        try {
            await updateStatus({
                id: parseInt(statuses[index].id, 10),
                data: {
                    [field]: value,
                },
            })
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div className='settings-statuses'>
            <h2>Staatused</h2>
            <div className='property-statuses'>
                <div className='property-statuses--list'>
                {
                    types.map((type) => (
                        <div className='status-type' key={type.value}>
                            <h3>{ t(`property_status.${type.label}`) }</h3>
                            { statuses?.filter(i => i.type === type.value).map((status, index) => (
                                <div key={status.id} className='property-statuses--status'>
                                    <div>
                                        <Input
                                            label={ t('property_status.label') }
                                            value={status.label}
                                            onChange={(e) => handleUpdate(index, 'label', e.target.value, type)}
                                        />
                                        <Input
                                            label={ t('property_status.color') }
                                            value={status.color}
                                            onChange={(e) => handleUpdate(index, 'color', e.target.value, type)}
                                        />
                                        <SelectInput
                                            label={ t('property_status.priority') }
                                            options={priorities}
                                            value={priorities.find(({ value }) => value === status.priority)}
                                            getOptionLabel={(option) => t(`property_status.${option.label}`)}
                                            onChange={(option) => handleUpdate(index, 'priority', option.value, type)}
                                        />
                                    </div>
                                    {/* <Button
                                        className={'btn-remove'}
                                        icon={<DeleteIcon />}
                                        onClick={() => handleRemove(status.id)}
                                    /> */}
                                </div>
                            )) }
                        </div>
                    ))
                }
                </div>
                <div className='property-statuses--add'>
                    <h4>{ t('property_status.add_new') }</h4>
                    <Input
                        label={ t('property_status.label') }
                        placeholder={ t('property_status.label') }
                        value={item.label}
                        onChange={(e) => setField('label', e.target.value)}
                    />
                    <Input
                        label={ t('property_status.color') }
                        placeholder={ t('property_status.color') }
                        value={item.color}
                        onChange={(e) => setField('color', e.target.value)}
                    />
                    <SelectInput
                        label={ t('property_status.priority') }
                        options={priorities}
                        value={priorities.find(({ value }) => value === item.priority)}
                        getOptionLabel={(option) => t(`property_status.${option.label}`)}
                        onChange={(option) => setField('priority', option.value)}
                    />
                    <SelectInput
                        label={ t('property_status.type') }
                        options={types}
                        value={types.find(({ value }) => value === item.type)}
                        getOptionLabel={(option) => t(`property_status.${option.label}`)}
                        onChange={(option) => setField('type', option.value)}
                    />

                    <Button
                        label={t('property_status.add_new')}
                        onClick={handleAdd}
                        loading={loading}
                    />
                </div>
            </div>
        </div>
    )
}

export default Statuses