const Login = `
    mutation login ($email: String!, $password: String!) {
        login (email: $email, password: $password) {
            status
            token
        }
    }
`

const ResetPassword = `
    mutation resetPassword ($email: String!) {
        resetPassword (email: $email)
    }
`

const ChangePassword = `
    mutation changePassword ($password: String!) {
        changePassword (password: $password)
    }
`

const ValidateToken = `
    mutation validateToken ($token: String!) {
        validateToken (token: $token)
    }
`

const CreateUser = `
    mutation createUser ($name: String!, $email: String!, $role: String!) {
        createUser (name: $name, email: $email, role: $role)
    }
`

const UpdateUser = `
    mutation updateUser ($id: Int!, $data: UserInput!) {
        updateUser (id: $id, data: $data)
    }
`

const CreateDeal = `
    mutation createDeal ($input: DealInput!) {
        createDeal (input: $input)
    }
`

const RemoveDeal = `
    mutation removeDeal ($ids: [Int!]!) {
        removeDeal (ids: $ids)
    }
`

const AssignDeal = `
    mutation assignDeal ($ids: [Int!]!, $userId: Int) {
        assignDeal (ids: $ids, userId: $userId)
    }
`

const UpdateDeal = `
    mutation updateDeal ($id: Int!, $data: DealInput!) {
        updateDeal (id: $id, data: $data)
    }
`

const CreateProperty = `
    mutation createProperty ($propertyId: String!, $dealId: String!) {
        createProperty (propertyId: $propertyId, dealId: $dealId)
    }
`

const CreateStatus = `
    mutation createStatus ($label: String!, $color: String!, $type: String!, $priority: Int!, $visible: Boolean!) {
        createStatus (label: $label, color: $color, type: $type, priority: $priority, visible: $visible)
    }
`

const ImportProperties = `
  mutation importProperties ($file: File!) {
    importProperties (file: $file) {
      code
      items {
        propertyId
      }
    }
  }
`

const AddComment = `
    mutation addComment ($dealId: Int!, $comment: String!) {
        addComment (dealId: $dealId, comment: $comment)
    }
`

const RemoveComment = `
    mutation removeComment ($commentId: Int!) {
        removeComment (commentId: $commentId)
    }
`

const EditComment = `
    mutation editComment ($id: Int!, $message: String) {
        editComment (id: $id, message: $message)
    }
`

const RemoveStatus = `
    mutation removeStatus ($id: Int!) {
        removeStatus (id: $id)
    }
`
const UpdateStatus = `
    mutation updateStatus ($id: Int!, $data: StatusInput!) {
        updateStatus (id: $id, data: $data)
    }
`

const SaveFilterView = `
    mutation saveFilterView ($label: String!, $content: String!) {
        saveFilterView (label: $label, content: $content)
    }
`

const StopTask = `
    mutation stopTask ($id: Int!) {
        stopTask (id: $id)
    }
`

const StopBot = `
    mutation stopBot {
        stopBot
    }
`

const StartBot = `
    mutation startBot ($from: Date!, $to: Date!, $county: String) {
        startBot (from: $from, to: $to, county: $county)
    }
`

const RemoveBotItem = `
    mutation removeBotItem ($ids: [Int!]!) {
        removeBotItem (ids: $ids)
    }
`

const SetPriority = `
    mutation setPriority ($ids: [Int!]!) {
        setPriority (ids: $ids)
    }
`

const AssignPriority = `
    mutation assignPriority ($id: Int!) {
        assignPriority (id: $id)
    }
`

const RemovePriority = `
    mutation removePriority ($ids: [Int!]!) {
        removePriority (ids: $ids)
    }
`

export {
    Login,
    ResetPassword,
    ChangePassword,
    ValidateToken,
    CreateUser,
    UpdateUser,
    CreateProperty,
    CreateDeal,
    RemoveDeal,
    AssignDeal,
    UpdateDeal,
    ImportProperties,
    AddComment,
    RemoveComment,
    EditComment,
    CreateStatus,
    RemoveStatus,
    UpdateStatus,
    SaveFilterView,
    StopTask,
    StartBot,
    RemoveBotItem,
    SetPriority,
    AssignPriority,
    RemovePriority,
    StopBot,
}