const DealAdded = `
    subscription dealAdded {
        dealAdded {
            id
            name
            createdAt
            updatedAt
            status {
                id
                label
            }
            adminStatus {
                id
                label
            }
            creator {
                id
                name
            }
            assignee {
                id
                name
            }
            comments {
                id
                updatedAt
                content
                creator {
                    id
                    name
                }
            }
            properties {
                id
                property {
                    id
                    propertyId
                    size
                    sizeUnit
                    municipality
                    type
                    address
                    city
                    county
                    price
                    status {
                        id
                        label
                        color
                        priority
                    }
                }
            }
        }
    }
`

const DealAddedToUser = `
    subscription dealAddedToUser {
        dealAddedToUser {
            id
            name
            createdAt
            updatedAt
            status {
                id
                label
            }
            adminStatus {
                id
                label
            }
            creator {
                id
                name
            }
            assignee {
                id
                name
            }
            comments {
                id
                updatedAt
                content
                creator {
                    id
                    name
                }
            }
            properties {
                id
                property {
                    id
                    propertyId
                    size
                    sizeUnit
                    municipality
                    type
                    address
                    city
                    county
                    price
                    status {
                        id
                        label
                        color
                        priority
                    }
                }
            }
        }
    }
`

const DealUpdated = `
    subscription dealUpdated {
        dealUpdated {
            id
            name
            createdAt
            updatedAt
            status {
                id
                label
            }
            adminStatus {
                id
                label
            }
            creator {
                id
                name
            }
            assignee {
                id
                name
            }
            comments {
                id
                updatedAt
                content
                creator {
                    id
                    name
                }
            }
            properties {
                id
                property {
                    id
                    propertyId
                    size
                    sizeUnit
                    municipality
                    type
                    address
                    city
                    county
                    price
                    status {
                        id
                        label
                        color
                        priority
                    }
                }
            }
        }
    }
`

const DealRemoved = `
    subscription dealRemoved {
        dealRemoved
    }
`

const DealRemovedFromUser = `
    subscription dealRemovedFromUser {
        dealRemovedFromUser
    }
`

const FilterViewAdded = `
    subscription filterViewAdded {
        filterViewAdded {
            id
            label
            content
        }
    }
`

const TaskUpdated = `
    subscription taskUpdated {
        taskUpdated {
            id
            type
            createdAt
            updatedAt
            description
            creator {
                id
                name
            }
            error
            stopped
            end
        }
    }
`

const PriorityUpdated = `
    subscription priorityUpdated {
        priorityUpdated {
            id
            createdAt
            assigneeId
            complete
            deal {
                id
                name
            }
            creator {
                id
                name
            }
            assignee {
                id
                name
            }
        }
    }
`

const PriorityAdded = `
    subscription priorityAdded {
        priorityAdded {
            id
            createdAt
            assigneeId
            complete
            deal {
                id
                name
            }
            creator {
                id
                name
            }
            assignee {
                id
                name
            }
        }
    }
`

const PriorityRemoved = `
    subscription priorityRemoved {
        priorityRemoved
    }
`

export {
    DealUpdated,
    DealAdded,
    DealRemoved,
    DealRemovedFromUser,
    TaskUpdated,
    DealAddedToUser,
    PriorityUpdated,
    PriorityRemoved,
    PriorityAdded,
}