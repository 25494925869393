import React from 'react'
import PropTypes from 'prop-types'
import ExclamationIcon from './icons/exclamation'

const BoxNotification = ({
    message,
    type,
}) => (
    !message ?
    <></>
    :
    <div className={`box-notification${type ? ` box-notification--${type}` : 'box-notification--info'}`}>
        <ExclamationIcon />
        <span>{ message }</span>
    </div>
)

BoxNotification.propTypes = {
    message: PropTypes.string,
    type: PropTypes.oneOf(['info', 'warning', 'error']),
}

export default BoxNotification