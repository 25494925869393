import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import SelectInput from './SelectInput'
import SearchIcon from './icons/search'
import FiltersIcon from './icons/filters'
import Button from './Button'
import { GetFilterViews, GetStatuses, GetUsers } from '../graphql/queries'
import { useQuery, useSubscription } from 'urql'
import Checkbox from './Checkbox'
import { PropertyType, SizeUnit, UserRoles } from '../util/consts'
import { useSearchParams } from 'react-router-dom'
import { useAuth } from '../providers/auth'
import DateInput from './DateInput'
import { getUTCDate, parseDate } from '../util/format'
import { Municipalities } from '../util/municipalities'

const municipalities = Object.entries(Municipalities).map(([key, value]) => ({
    value: value.ONIMI,
    label: value.ONIMI,
}))

const propertyTypes = Object.entries(PropertyType).map(([, value]) => ({
    value,
    label: value,
}))

const sizeUnits = Object.entries(SizeUnit).map(([, value]) => ({
    value,
    label: `${value}`,
}))

const initialState = {
    query: '',
    assigned: undefined,
    unassigned: false,
    fromDate: '',
    toDate: '',
    creator: undefined,
    city: '',
    county: '',
    status: '',
    propertyType: '',
    propertyStatus: '',
    adminStatus: '',
    areaMin: '',
    areaMax: '',
    areaUnit: '',
    dateRange: [null, null],
    emptyClient: false,
}

const DealFilters = ({
    onFilterChange,
    onFilterSave,
}) => {

    const { t } = useTranslation()
    const { user } = useAuth()
    const [searchParams, setSearchParams] = useSearchParams()
    const [filters, setFilters] = useState(initialState)
    const [users, setUsers] = useState([])
    const [statuses, setStatuses] = useState([])
    const [views, setViews] = useState([])
    const [selectedView, setSelectedView] = useState()
    const [filtersOpened, setFiltersOpened] = useState(false)
    const [startDate, endDate] = filters?.dateRange ? filters.dateRange : [null, null]

    const [usersQuery] = useQuery({
        query: GetUsers,
        variables: {
            page: 0,
            limit: 100,
            orderBy: 'name',
        },
    })

    const [viewsQuery] = useQuery({
        query: GetFilterViews,
    })

    const [statusQuery] = useQuery({
        query: GetStatuses,
    })

    useEffect(() => {
        if (usersQuery?.fetching) return

        setUsers(usersQuery.data.getUsers || [])
    }, [usersQuery?.fetching, usersQuery?.data])

    useEffect(() => {
        if (statusQuery?.fetching) return

        setStatuses(statusQuery.data.getStatuses || [])
    }, [statusQuery?.fetching, statusQuery?.data])

    useEffect(() => {
        if (viewsQuery?.fetching) return

        setViews(viewsQuery.data.getFilterViews || [])
    }, [viewsQuery?.fetching, viewsQuery?.data])

    useEffect(() => {
        if (onFilterChange) onFilterChange(filters)
    }, [])

    useEffect(() => {
        const loadFilters = {
            query: '',
        }

        for (const [param, value] of searchParams.entries()) {
            let finalValue = value

            if (['assigned', 'status', 'propertyStatus', 'adminStatus'].includes(param)) {
                finalValue = value.split(',').map(v => parseInt(v))
            }
            if (['dateRange'].includes(param)) {
                finalValue = value.split(',').map(v => v ? new Date(v) : null)
            }
            if (['propertyType', 'municipality'].includes(param)) {
                finalValue = value.split(',')
            }
            if (['unassigned', 'emptyClient'].includes(param)) {
                finalValue = value === 'true' ? true : false
            }

            loadFilters[param] = finalValue
        }

        setFilters({
            ...loadFilters,
        })

        if (onFilterChange) onFilterChange({
            ...loadFilters,
            ...(loadFilters?.dateRange?.length && {
                dateRange: undefined,
                fromDate: getUTCDate(loadFilters.dateRange[0]),
                toDate: getUTCDate(loadFilters.dateRange[1]),
            }),
        })
    }, [searchParams])

    const assignSearchParams = (params) => {
        const newParams = {}
        for (const [key, value] of Object.entries(params)) {
            if (!value) continue
            if (key === 'dateRange') {
                newParams[key] = value.map(v => parseDate(v)).join(',')
                continue
            }
            newParams[key] = Array.isArray(value) ? value.join(',') : value
        }

        setSearchParams({
            ...newParams,
        })
    }

    const setField = (field, value) => {
        const newVal = {
            ...filters,
            [field]: value,
        }

        setFilters(newVal)
        assignSearchParams(newVal)
        setSelectedView(null)

        if (onFilterChange) onFilterChange(newVal)
    }

    console.log(users)

    return (
        <div
            className={`filters-wrapper filters-wrapper--deal${filtersOpened ? ' filters--open' : ''}`}
        >
            <Button
                className='btn-filters'
                icon={<FiltersIcon fill={'#fff'} />}
                label={filtersOpened ? t('filters.filter_close') : t('filters.filter')}
                onClick={() => setFiltersOpened(!filtersOpened)}
            />
            <div className='filters filters-deal'>
                <div className='filters-deal--row'>
                    <div className='filters-search--query'>
                        <Input
                            placeholder={t('deals.search_deal')}
                            onChange={(e) => setField('query', e.target.value)}
                            value={filters.query}
                            icon={<SearchIcon />}
                            isClearable
                        />
                    </div>
                    <SelectInput
                        placeholder={t('deals.property_type')}
                        className={'filters-search--property-type'}
                        options={propertyTypes}
                        value={!filters?.propertyType?.length ? undefined : propertyTypes.filter(x => filters.propertyType.includes(x.value))}
                        getOptionLabel={(option) => t(`property_type.${option.label}`)}
                        onChange={(options) => setField('propertyType', options?.length ? options.map(i => i.value) : undefined)}
                        isClearable={true}
                        isMulti
                    />
                    {
                        user?.role !== UserRoles.Admin ?
                            <></>
                            :
                            <SelectInput
                                placeholder={t('deals.choose_user')}
                                options={users?.filter(x => x.name !== 'Developer')}
                                value={!filters?.assigned?.length ? undefined : users.filter(x => filters.assigned.includes(x.id))}
                                getOptionLabel={(option) => t(option.name)}
                                getOptionValue={(option) => option.id}
                                onChange={(options) => setField('assigned', options?.length ? options.map(i => i.id) : undefined)}
                                isClearable={true}
                                isMulti
                            />
                    }
                    <DateInput
                        placeholder={t('deals.updated_at')}
                        onChange={(val) => setField('dateRange', val)}
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        isClearable
                    />
                    <div className='filters-deal--area'>
                        <Input
                            placeholder={t('deals.area_min')}
                            onChange={(e) => setField('areaMin', e.target.value)}
                            value={filters.areaMin}
                            isClearable
                        />
                        <Input
                            placeholder={t('deals.area_max')}
                            onChange={(e) => setField('areaMax', e.target.value)}
                            value={filters.areaMax}
                            isClearable
                        />
                        <SelectInput
                            placeholder={t('deals.property_size_unit')}
                            options={sizeUnits}
                            value={sizeUnits.find(x => x.value === filters.areaUnit)}
                            getOptionLabel={(option) => t(option.label)}
                            onChange={(option) => setField('areaUnit', option?.value)}
                            menuPlacement={'bottom'}
                            isClearable
                        />
                    </div>
                </div>
                <div className='filters-deal--row'>
                    <SelectInput
                        placeholder={t('deals.deal_status')}
                        className={'filters-search--status'}
                        options={statuses?.filter(i => i.type === 'DEAL')}
                        value={!filters.status?.length ? undefined : statuses.filter(x => filters.status.includes(x.id))}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.label}
                        onChange={(options) => setField('status', options?.length ? options.map(i => i.id) : undefined)}
                        isClearable={true}
                        isMulti
                    />
                    <SelectInput
                        placeholder={t('deals.property_status')}
                        className={'filters-search--status'}
                        options={statuses?.filter(i => i.type === 'PROPERTY')}
                        value={!filters.propertyStatus?.length ? undefined : statuses.filter(x => filters.propertyStatus.includes(x.id))}
                        getOptionValue={(option) => option.id}
                        getOptionLabel={(option) => option.label}
                        onChange={(options) => setField('propertyStatus', options?.length ? options.map(i => i.id) : undefined)}
                        isClearable={true}
                        isMulti
                    />
                    <SelectInput
                        placeholder={t('deals.municipalities')}
                        className={'filters-search--property-type'}
                        options={municipalities}
                        value={!filters?.municipality?.length ? undefined : municipalities.filter(x => filters.municipality.includes(x.value))}
                        getOptionLabel={(option) => option.label}
                        onChange={(options) => setField('municipality', options?.length ? options.map(i => i.value) : undefined)}
                        isClearable={true}
                        isMulti
                    />
                    {
                        user?.role === UserRoles.Admin ?
                            <SelectInput
                                placeholder={t('deals.admin_status')}
                                className={'filters-search--status'}
                                options={statuses?.filter(i => i.type === 'ADMIN')}
                                value={!filters?.adminStatus?.length ? undefined : statuses.find(x => filters.adminStatus.includes(x.id))}
                                getOptionValue={(option) => option.id}
                                getOptionLabel={(option) => option.label}
                                onChange={(options) => setField('adminStatus', options?.length ? options.map(i => i.id) : undefined)}
                                isClearable={true}
                                isMulti
                            />
                            :
                            <></>
                    }

                    {
                        user?.role === UserRoles.Admin ?
                            <div className='filters-search--checkboxes'>
                                <Checkbox
                                    label={`${t('deals.unassigned')}`}
                                    value={filters.unassigned ?? false}
                                    onChange={(e) => setField('unassigned', e.target.checked)}
                                />
                                <Checkbox
                                    label={`${t('deals.empty_clients')}`}
                                    value={filters.emptyClient ?? false}
                                    onChange={(e) => setField('emptyClient', e.target.checked)}
                                />
                            </div>
                            :
                            <></>
                    }
                </div>
            </div>
        </div>
    )
}

DealFilters.propTypes = {
    onFilterChange: PropTypes.func,
}

export default DealFilters