import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const EmptyList = ({
    message,
}) => {
    
    const { t } = useTranslation()

    return (
        <div className='empty-list'>
            <h3>{ message || t('no_content') }</h3>
        </div>
    )
}

EmptyList.propTypes = {
    message: PropTypes.string,
}

export default EmptyList