import React, { useRef, useState } from 'react'
import './style.scss'
import { useTranslation } from 'react-i18next'
import AddUserModal from '../../components/AddUserModal'
import Button from '../../components/Button'
import FilterTable from '../../components/FilterTable'
import ScreenHeader from '../../components/ScreenHeader'
import { GetUsers } from '../../graphql/queries'
import UserFilters from '../../components/UserFilters'
import EditUserModal from '../../components/EditUserModal'

const includeFields = [
    {
        value: 'id',
        label: 'ID',
    },
    {
        value: 'name',
        label: 'Nimi',
    },
    {
        value: 'email',
        label: 'E-post',
    },
    {
        value: 'role',
        label: 'Roll',
    },
]

const UsersScreen = () => {

    const { t } = useTranslation()
    const tableRef = useRef()
    const [extraFilters, setExtraFilters] = useState()
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [selectedRow, setSelectedRow] = useState()

    const fieldConditions = (row, field) => {
        switch (field) {
            case 'role':
                return t(`roles.${row.role}`)
            default:
                return row[field]
        }
    }

    const handleAddSuccess = () => {
        tableRef?.current?.refresh()
        setShowAddModal(false)
    }

    const handleEditSuccess = () => {
        tableRef?.current?.refresh()
        setShowEditModal(false)
    }

    const handleFilterChange = (filters) => {
        setExtraFilters(filters)
    }

    const handleRowClick = (row) => {
        console.log(row)
        setSelectedRow(row)
        setShowEditModal(true)
    }

    const renderHeaderActions = () => (
        <>
            <Button
                label={t('profile.add_user')}
                onClick={() => setShowAddModal(true)}
            />
        </>
    )

    return (
        <div className='users'>
            <ScreenHeader
                title={ t('users.title') }
                actions={renderHeaderActions}
            />
            <UserFilters
                onFilterChange={handleFilterChange}
            />
            <FilterTable
                ref={tableRef}
                query={GetUsers}
                queryKey='getUsers'
                includeFields={includeFields}
                fieldConditions={fieldConditions}
                initialOrderBy='name'
                hideInputs={true}
                extraFilters={extraFilters}
                useExtraFilters={true}
                onRowClick={handleRowClick}
            />
            <AddUserModal
                show={showAddModal}
                onSuccess={handleAddSuccess}
                close={() => setShowAddModal(false)}
            />
            <EditUserModal
                show={showEditModal}
                onSuccess={handleEditSuccess}
                close={() => setShowEditModal(false)}
                user={selectedRow}
            />
        </div>
    )
}

export default UsersScreen