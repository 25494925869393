import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'urql'
import Button from './Button'
import Modal from './Modal'
import { UpdateDeal } from '../graphql/mutations'
import BoxNotification from './BoxNotification'
import PropertyForm from './PropertyForm'
import ContactForm from './ContactForm'
import Input from './Input'
import SelectInput from './SelectInput'
import { GetDeal, GetStatuses, GetUsers } from '../graphql/queries'
import CommentForm from './CommentForm'
import ActivityIndicator from './ActivityIndicator'
import { UserRoles } from '../util/consts'
import { useAuth } from '../providers/auth'
import SetPriorityButton from './SetPriorityButton'

const initialState = {
    name: '',
    status: '',
    adminStatus: '',
    properties: [],
    contacts: [],
    assignedUser: null,
}

const initialErrorsState = {
    name: null,
    generic: null,
}

const EditDealModal = ({
    data,
    show,
    close,
    onSuccess,
}) => {
    const { t } = useTranslation()
    const { user } = useAuth()
    const [item, setItem] = useState(data || initialState)
    const [properties, setProperties] = useState([])
    const [contacts, setContacts] = useState([])
    const [comments, setComments] = useState([])
    const [errors, setErrors] = useState(initialErrorsState)
    const [, updateDeal] = useMutation(UpdateDeal)
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [statuses, setStatuses] = useState([])

    const [dealQuery] = useQuery({
        requestPolicy: 'network-only',
        query: GetDeal,
        pause: !item?.id,
        variables: {
            id: item?.id,
        },
    })

    const [statusQuery] = useQuery({
        requestPolicy: 'network-only',
        pause: !item,
        query: GetStatuses,
    })

    const [usersQuery] = useQuery({
        query: GetUsers,
        variables: {
            page: 0,
            limit: 100,
            orderBy: 'name',
        },
    })
    
    useEffect(() => {
        setItem(data || initialState)
    }, [data])

    useEffect(() => {
        if (show) return
        setItem(initialState)
        setProperties([])
        setContacts([])
        setComments([])
    }, [show])

    useEffect(() => {
        if (dealQuery?.fetching) return

        if (!dealQuery.data?.getDeal) return

        const newItem = {
            ...dealQuery.data.getDeal,
            assignedUser: users?.length && dealQuery.data.getDeal.assignee?.id ? users.find(x => x.id === dealQuery.data.getDeal.assignee.id) : null,
        }

        setItem(newItem)
        setContacts(newItem.contacts)
        setProperties(newItem.properties)
        setComments(newItem.comments)
    }, [dealQuery?.fetching, dealQuery?.data])

    useEffect(() => {
        if (usersQuery?.fetching) return

        setUsers(usersQuery.data.getUsers || [])
    }, [usersQuery?.fetching, usersQuery?.data])

    useEffect(() => {
        if (statusQuery?.fetching) return

        setStatuses(statusQuery.data.getStatuses || [])
    }, [statusQuery?.fetching, statusQuery?.data])

    const handleEdit = async () => {
        setLoading(true)
        try {
            const input = {
                properties: properties.map(x => {

                    console.log(x)
                    if (x.property) {
                        delete x.property.__typename
                        return {
                            ...x.property,
                            status: x.property.status ? x.property.status.id : undefined,
                            type: x.property.type || null,
                            size: x.property?.size ? parseFloat(`${x.property.size}`.replace(',', '.')) : undefined,
                        }
                    }
                    delete x.__typename
                    return {
                        ...x,
                        status: x.status ? x.status.id : undefined,
                        type: x.type || null,
                        size: x.size ? parseFloat(`${x.size}`.replace(',', '.')) : undefined,
                    }
                }),
                contacts: contacts.map(x => {
                    if (x.contact) {
                        delete x.contact.__typename
                        return {
                            ...x.contact,
                        }
                    }
                    delete x.__typename
                    return x
                }),
                comments: comments.map(x => {
                    return {
                        id: x.id,
                        content: x.content,
                    }
                })
            }

            console.log(input)

            if (item.assignedUser) input.assignedUser = item.assignedUser.id
            if (item.name) input.name = item.name
            if (item.status?.id) input.status = item.status.id
            if (item.adminStatus?.id) input.adminStatus = item.adminStatus.id

            await updateDeal({
                id: item?.id,
                data: input,
            })

            if (onSuccess) onSuccess()
        } catch (err) {
            setErrors({
                ...initialErrorsState,
                generic: t('deals.update_error'),
            })
            console.log(err)
        } finally {
            setLoading(false)
        }
    }

    const setField = (field, value) => {
        const updatedItem = {
            ...item,
            [field]: value,
        }

        setItem({...updatedItem})
    }

    const handleClose = () => {
        setErrors(initialErrorsState)
        setItem(initialState)

        if (close) close()
    }

    const handleUpdate = (field, value) => {
        if (field === 'properties') {
            setProperties([...value])
            setErrors({
                ...errors,
                properties: null,
            })
            return
        }
        if (field === 'contacts') {
            setContacts([...value])
            setErrors({
                ...errors,
                contacts: null,
            })
            return
        }
        if (field === 'comments') {
            setComments([...value])
            setErrors({
                ...errors,
                comments: null,
            })
            return
        }
    }

    return (
        <Modal
            className={'modal-deal modal-deal-edit'}
            show={show}
            close={handleClose}
            title={ t('deals.edit_title') }
            renderActions={
                <div className='modal-actions'>
                    <Button
                        onClick={handleEdit}
                        label={t('deals.update_deal')}
                        loading={loading}
                    />
                </div>
            }
        >
            {
                dealQuery?.fetching ?
                <ActivityIndicator message={t('deals.loading_deal')} />
                :
                <>
                    <div className='modal-deal--main'>
                        <Input
                            label={t('deals.name')}
                            value={item.name}
                            onChange={e => setField('name', e.target.value)}
                        />
                        {
                            user?.role === UserRoles.Admin ?
                            <SelectInput
                                label={ t('deals.assign_user') }
                                options={users}
                                value={item.assignedUser}
                                getOptionLabel={(option) => option.name}
                                getOptionValue={(option) => option.id}
                                onChange={(option) => setField('assignedUser', option)}
                            />
                            :
                            <></>
                        }
                        <SelectInput
                            label={ t('deals.status') }
                            options={statuses.filter(i => i.type === 'DEAL')}
                            value={item.status}
                            getOptionLabel={(option) => option.label}
                            getOptionValue={(option) => option.id}
                            onChange={(option) => setField('status', option)}
                            isClearable={true}
                        />
                        {
                            user?.role === UserRoles.Admin ?
                            <>
                                <SelectInput
                                    label={ t('deals.admin_status') }
                                    options={statuses.filter(i => i.type === 'ADMIN')}
                                    value={item.adminStatus}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.id}
                                    onChange={(option) => setField('adminStatus', option)}
                                    isClearable={true}
                                />
                                <SetPriorityButton
                                    dealId={item?.id}
                                />
                            </>
                            :
                            <></>
                        }
                    </div>
                    <div className='modal-deal--info'>
                        {
                            properties?.length > 0 ?
                            <PropertyForm
                                initialData={properties}
                                onChange={(data) => handleUpdate('properties', data)}
                                error={errors.properties}
                                collapsible={true}
                                dealId={item?.id}
                            />
                            :
                            <></>
                        }
                        {
                            contacts?.length > 0 ?
                            <ContactForm
                                initialData={contacts}
                                onChange={(data) => handleUpdate('contacts', data)}
                                error={errors.contacts}
                                dealId={item?.id}
                            />
                            :
                            <></>
                        }
                        <CommentForm
                            initialData={comments}
                            onChange={(data) => handleUpdate('comments', data)}
                            error={errors.comments}
                        />
                    </div>

                    {
                        errors?.generic ?
                        <BoxNotification
                            message={errors.generic}
                            type={'error'}
                        />
                        :
                        <></>
                    }
                </>
            }
        </Modal>
    )
}

EditDealModal.propTypes = {
    data: PropTypes.object,
    show: PropTypes.bool,
    close: PropTypes.func,
    onSuccess: PropTypes.func,
}

export default EditDealModal