import React from 'react'
import PropTypes from 'prop-types'

const DeleteIcon = ({ fill }) => (
    <svg version="1.1" baseProfile="tiny" fill={fill || '#010C3A'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 459 459" xmlSpace="preserve">
        <path d="M229.5,0C102.751,0,0,102.751,0,229.5S102.751,459,229.5,459S459,356.249,459,229.5S356.249,0,229.5,0z M307.105,271.629
            c9.797,9.797,9.797,25.68,0,35.477c-4.898,4.898-11.318,7.347-17.738,7.347c-6.42,0-12.84-2.449-17.738-7.347L229.5,264.977
            l-42.128,42.129c-4.898,4.898-11.318,7.347-17.738,7.347c-6.42,0-12.84-2.449-17.738-7.347c-9.797-9.796-9.797-25.68,0-35.477
            l42.129-42.129l-42.129-42.129c-9.797-9.797-9.797-25.68,0-35.477s25.68-9.797,35.477,0l42.128,42.129l42.128-42.129
            c9.797-9.797,25.68-9.797,35.477,0c9.797,9.796,9.797,25.68,0,35.477l-42.13,42.129L307.105,271.629z"/>
    </svg>
)

DeleteIcon.propTypes = {
    fill: PropTypes.string,
}

export default DeleteIcon