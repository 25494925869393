import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Input from './Input'
import Button from './Button'
import PlusIcon from './icons/plus'
import BoxNotification from './BoxNotification'
import DeleteIcon from './icons/delete'
import Checkbox from './Checkbox'
import ChevronIcon from './icons/chevron-bold'
import ExistingContact from './ExistingContact'

const initialContact = {
    name: '',
    pid: '',
    phone: '',
    email: '',
    main: false,
}

const ContactInput = ({
    data,
    index,
    onChange,
    onRemove,
    dealId,
}) => {
    const { t } = useTranslation()
    const [item, setItem] = useState(data || initialContact)
    const [expanded, setExpanded] = useState(data ? false : true)

    const setField = (field, value) => {
        const newItem = {
            ...item,
            [field]: value,
        }
        setItem(newItem)
        if (onChange) onChange(newItem)
    }

    if (!item) return <></>
    
    return (
        <div className='contact-wrapper'>
            <div className={'contact'}>
                <div className='contact-main'>
                <Input
                            label={ t('deals.contact_name') }
                            placeholder={ t('deals.contact_name') }
                            value={item.name}
                            onChange={(e) => setField('name', e.target.value)}
                        />

                    <Checkbox
                            label={`${t('deals.main_contact')}`}
                            value={item.main ?? false}
                            onChange={(e) => setField('main', e.target.checked)}
                        />
                                            <div className='alert-input'>
                        <Input
                            label={ t('deals.pid') }
                            placeholder={ t('deals.pid') }
                            value={item.pid}
                            onChange={(e) => setField('pid', e.target.value)}
                        />
                        <ExistingContact pid={item.pid} dealId={dealId} />
                    </div>
                </div>
                {
                    expanded ?
                    <div className='contact-extended'>
                        <Input
                            label={ t('deals.phone') }
                            placeholder={ t('deals.phone') }
                            value={item.phone}
                            onChange={(e) => setField('phone', e.target.value)}
                        />
                        <Input
                            label={ t('deals.email') }
                            placeholder={ t('deals.email') }
                            value={item.email}
                            onChange={(e) => setField('email', e.target.value)}
                        />
                    </div>
                    :
                    <></>
                }
                <div className='actions'>
                <Button
                    label={expanded ? t('deals.contract') : t('deals.expand')}
                    className={`btn-expand${expanded ? ' expanded' : ''}`}
                    onClick={() => setExpanded(!expanded)}
                    icon={<ChevronIcon />}
                />
                {
                    onRemove && index !== 0 ?
                    <Button
                        label={t('deals.remove_row')}
                        className={'btn-remove'}
                        icon={<DeleteIcon />}
                        onClick={onRemove}
                    />
                    :
                    <></>
                }
                </div>
            </div>
        </div>
    )
}

ContactInput.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func,
}

const ContactForm = ({
    initialData,
    onChange,
    error,
    dealId,
}) => {
    const { t } = useTranslation()
    const [items, setItems] = useState(initialData?.length ? initialData : [initialContact])

    useEffect(() => {
        if (onChange) onChange(items)
    }, [])

    useEffect(() => {
        if (initialData) setItems([...initialData])
    }, [initialData])

    const handleAdd = () => {
        const newItems = [...items, initialContact]
        setItems(newItems)

        if (onChange) onChange(newItems)
    }

    const updateItems = (item, index) => {
        const newItems = [...items]
        newItems[index] = item

        if (onChange) onChange(newItems)
    }

    const handleRemove = (index) => {
        if (index === 0) return

        const newItems = items.filter((_, i) => i !== index)
        setItems(newItems)

        if (onChange) onChange(newItems)
    }
    
    return (
        <div className={'contacts'}>
            <h4>{ t('deals.contacts') }</h4>
            {
                items.map((item, index) =>
                    <ContactInput
                        key={`contact-${index}`}
                        data={item.contact ? {
                            ...item.contact,
                            main: item.main,
                        } : item}
                        index={index}
                        onChange={(data) => updateItems(data, index)}
                        onRemove={() => handleRemove(index)}
                        dealId={dealId}
                    />
                )
            }
            {
                error ?
                <BoxNotification
                    message={error}
                    type={'error'}
                />
                :
                <></>
            }
            <Button
                label={t('deals.add_contact')}
                onClick={handleAdd}
                icon={<PlusIcon />}
            />
        </div>
    )
}

ContactForm.propTypes = {
    onChange: PropTypes.func,
}

export default ContactForm